<template>
	<div>
		<el-form ref="cForm" :model="cForm" :rules="vRules" :label-position="'right'" style="padding-right: 20px;"
			label-width="150px" size="small">
			<div class="mt10 inner-box">
				<el-form-item label="资金方名称：" prop="name">
					<el-input v-model="cForm.name" placeholder="请输入资金方名称" />
				</el-form-item>
				<el-form-item label="资金方所属企业：" prop="enterpriseId">
					<el-select style="width: 100%;" v-model="cForm.enterpriseId" placeholder="请选择资金方所属企业">
						<el-option v-for="item in enterpriseList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="账户系统：" prop="paymentType">
					<el-select style="width: 100%;" v-model="cForm.paymentType" placeholder="请选择账户系统">
						<el-option v-for="item in paymentList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属产品：" prop="productType">
					<el-select style="width: 100%;" v-model="cForm.productType " placeholder="请选择所属产品">
						<el-option v-for="item in industryList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
        <div v-if="cForm.productType.toString() == '0'">
          <el-form-item
            label="资金方额度预审核："
            prop="toQuotaAudit"
          >
            <el-select style="width: 100%;" v-model="cForm.toQuotaAudit" placeholder="请选择">
              <el-option label="是" :value="2"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="cForm.productType.toString() == '0'">
          <el-form-item
            label="资金方支用审核："
            prop="toAudit"
            :rules="[
              { required: true, message: '请选择资金方支用审核', trigger: ['blur', 'change'] }
            ]"
          >
            <el-select style="width: 100%;" v-model="cForm.toAudit" placeholder="请选择">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </div>
				<el-form-item label="资金方场景ID：" prop="accountScene">
					<el-input v-model="cForm.accountScene" placeholder="请输入资金方场景ID" />
				</el-form-item>
				<el-form-item label="资金成本（年化）%：" prop="costRate">
					<el-input-number  style="width: 100%;"  v-model="cForm.costRate" :precision="2" :step="0.1" :max="10"></el-input-number>
				</el-form-item>
				<el-form-item label="通道费率（年化）%：" prop="routingRate">
					<el-input-number style="width: 100%;" v-model="cForm.routingRate" :precision="2" :step="0.1" :max="10"></el-input-number>
				</el-form-item>
				<el-form-item label="资金方备注信息：" prop="remark">
					<el-input v-model="cForm.remark" placeholder="请输入备注信息" />
				</el-form-item>
			</div>
			<div class="tc">
				<el-button type="primary" size="medium" :loading="loading" @click="saveCard()">确认新增</el-button>
			</div>
		</el-form>

	</div>
</template>

<script>
	export default {
		name: "ChannelConfigRate",
		data() {
			return {
				paymentList: [{
					value: '1',
					label: 'PING++'
				}, {
					value: '2',
					label: '富友支付'
				}],
				industryList: [{
					value: '0',
					label: '1A-极速收款'
				}, {
					value: '1',
					label: '2A-极速付款'
				}],
				enterpriseList:[],
				loading: false,
				cForm: {
					name: null,
					costRate:0.00,
					routingRate:0.00,
					paymentType:'1',
					enterpriseId:'',
					productType :'',
					remark:'',
          toAudit: null,
          toQuotaAudit: null
				},
				vRules: {
					paymentType: [{
						required: true,
						trigger: 'blur',
						message: '请选择所属银行'
					}],
					name: [{
						required: true,
						trigger: 'blur',
						message: '请填写资金方名称'
					}],
					enterpriseId: [{
						required: true,
						trigger: 'blur',
						message: '请选择资金方所属企业'
					}],
					productType: [{
						required: true,
						trigger: 'blur',
						message: '请选择所属产品'
					}],
					accountScene: [{
						required: true,
						trigger: 'blur',
						message: '请填写资金方场景ID'
					}],
					costRate: [{
						required: true,
						trigger: 'blur',
						message: '请填写资金成本'
					}],
          toQuotaAudit: [{
            required: true,
						trigger: 'blur',
						message: '请选择资金方额度预审核'
          }],
					routingRate: [{
						required: true,
						trigger: 'blur',
						message: '请填写通道费率'
					}],
				},
			};
		},
		watch: {},
		mounted() {
			this.fundEnterprise()
		},
		methods: {
 			saveCard() {
				this.$refs.cForm.validate(valid => {
					if (valid) {
						this.loading = true;
						let form = {...this.cForm}
            if (form.productType == 1) {
              form.toAudit = 0;
              form.toQuotaAudit = 0;
            }
						form.costRate = (Number(form.costRate) / 100).toFixed(4)
						form.routingRate = (Number(form.routingRate) / 100).toFixed(4)
						this.doPost('bium-portal/controller/fin-ops/fundingPartner/save', form).then(res => {
							if(res.data.errCode == 1000){
								this.loading = false;
								this.$notify({
									title: '新增成功',
									type: 'success',
									duration: 1000
								});
								this.$emit("refreshTable",1)
								this.$parent.handleClose();
							}

						}).catch(e => {
							this.loading = false;
						});
					} else {
						return false;
					}
				});
			},
			fundEnterprise(){
				this.doPost('bium-portal/controller/fin-ops/enterprise/listFundEnterprise').then(res => {
							this.enterpriseList=res.data.result
						})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.dialogTitle {
		color: #D9001B;
		font-size: 16px;
		text-align: center;
	}

	.dialogContent {
		text-align: center;
		padding-top: 20px;
	}
</style>
