var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fileArr.length > 0
        ? _c(
            "div",
            [
              _c(
                "el-divider",
                {
                  staticClass: "title-divider",
                  attrs: { "content-position": "left" },
                },
                [_vm._v("协议文本\n    ")]
              ),
              _c("div", { staticClass: "jg-acc" }, [
                _c(
                  "div",
                  { staticClass: "card-box" },
                  _vm._l(_vm.fileArr, function (item, index) {
                    return _c("div", { key: index, staticClass: "file-item" }, [
                      _c("div", { staticClass: "file-item-line" }, [
                        _c("div", [
                          _c("div", { staticClass: "fc_333 fb" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.filename) +
                                "\n                "
                            ),
                            _c("label", [
                              _vm._v(
                                _vm._s(_vm.flowStatusToText[item.flowStatus]) +
                                  "/" +
                                  _vm._s(item.signStart)
                              ),
                            ]),
                          ]),
                        ]),
                        item.flowStatus == 9
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "fc_blue fc_333 fb chand",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openFile(item)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "查看图片",
            width: "600px",
            top: "100px",
            visible: _vm.isvisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isvisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex-c" }, [
            _c("img", {
              staticStyle: { width: "500px", height: "400px" },
              attrs: { src: _vm.fileUrl, alt: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }