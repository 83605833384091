<template>
    <div class="user">
      <basic-container class="full-condition">
        <avue-crud
          ref="crud"
          :option="option"
          :page.sync="page"
          :table-loading="listLoading"
          :data="dataList"
          :cell-class-name="handleCellClassName"
          @on-load="getList"
          @size-change="sizeChange"
          @current-change="currentChange"
          @search-change="handleFilter"
          @search-reset="resetFilter"
          @refresh-change="handleRefreshChange">
          <template slot="menuRight">
            <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button> -->
          </template>
          <template slot="menuLeft">
            <!-- <el-button
                class="filter-item"
                type="primary"
                icon="icon-YUAN"
                @click="handleWidthdrawal"
                >提现
              </el-button>
              <el-button
                class="filter-item"
                type="primary"
                icon="icon-YUAN"
                @click="checkFlow"
                >流水核查
              </el-button> -->
          </template>

          <template slot="menu" slot-scope="scope">
            <el-popconfirm
              title="确认要作废吗?"
              @confirm="onVoidOrder(scope.row)"
            >
              <el-button v-if="scope.row.showVoidOrderAction" slot="reference" type="text">作废</el-button>
              <!-- <el-button
                type="text"
                v-if="scope.row.showVoidOrderAction"
              >
                作废
              </el-button> -->
            </el-popconfirm>

            <!-- <el-button
              type="text"
              v-if="scope.row.finProcStatus=='放款成功'&&scope.row.productType=='0'"
              @click="toPath(scope)"
            >
              查看详情
            </el-button> -->
            <!-- <el-button type="text" icon="el-icon-refresh"
                            @click="queryBook(scope.row, scope.index)">账户同步</el-button> -->
            <!-- <el-button
                v-if="scope.row.status == 1"
                type="text"
                icon="el-icon-view"
                @click="frozenBook(scope.row, scope.index)"
                >冻结</el-button
              >
              <el-button
                v-if="scope.row.status == 2"
                type="text"
                icon="el-icon-view"
                @click="frozenBook(scope.row, scope.index)"
                >解冻</el-button
              > -->
          </template>
        </avue-crud>
      </basic-container>
    </div>
  </template>
  <script>
  import channelAdd from '@/views/channel/components/channelAdd'
  import { mapGetters } from 'vuex'
  const tableOption = {
    searchMenuSpan: 24,
    searchLabelWidth: 80,
    searchMenuPosition: 'center',
    maxHeight: 600,
    searchIndex: 3,
    searchShowBtn: false,
    border: true,
    showSummary: false,
    printBtn: false,
    index: true,
    indexLabel: '序号',
    stripe: true,
    menu: true,
    menuAlign: 'center',
    menuWidth: 200,
    searchIndex: 3,
    searchIcon: false,
    searchShowBtn: false,
    labelWidth: 100,
    editBtn: false,
    delBtn: false,
    align: 'center',
    addBtn: false,
    column: [
      {
        fixed: true,
        label: 'id',
        prop: 'id',
        span: 24,
        hide: true,
        editDisplay: false,
        addDisplay: false,
      },
      {
        label: '企业名称',
        prop: 'enterpriseName',
        width: 180,
        type: 'input',
        hide: true,
        editDisplay: true,
        addDisplay: false,
        search: true,
        searchSpan: 6,
        searchLabelWidth: 90,
        span: 24,
      },
      {
        label: '店铺名称',
        prop: 'storeName',
        width: 180,
        type: 'input',
        hide: true,
        editDisplay: true,
        addDisplay: false,
        search: true,
        searchSpan: 6,
        searchLabelWidth: 90,
        span: 24,
      },

      {
        label: '企业名称',
        prop: 'enterpriseName',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      // {
      //   label: '店铺名称',
      //   prop: 'storeName',
      //   editDisplay: false,
      //   addDisplay: false,
      //   span: 12,
      // },
      {
        label: '店铺名称/所属平台',
        prop: 'idLivePlatform',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: '可支用额度',
        prop: 'totalAmount',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: '融资折扣率',
        prop: 'financingRate',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: '支用申请金额',
        prop: 'applyAmount',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: '放款金额',
        prop: 'paymentAmount',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      // {
      //   label: '渠道费率',
      //   prop: 'customerRate',
      //   editDisplay: false,
      //   addDisplay: false,
      //   span: 12,
      // },
      {
        label: '费率/计息方式',
        prop: 'frozenAmount',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },

      {
        label: '额度生成日期',
        prop: 'created',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: '是否失效',
        prop: 'isExpiredStatus',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
    ],
  }

  export default {
    name: 'TableUser',
    components: {
      channelAdd,
    },
    data() {
      return {
        option: tableOption,
        searchItmes: {},
        dialogVisible: false,
        page: {
          total: 0, // 总页数
          currentPage: 1,
          pageSize: 10,
        },
        isvisible: false,
        dataList: [],
        listLoading: true,
        accountId: '',
        accountName: '',
        billingMethod: {
          Charge_Per_transaction: '单笔',
          Annualized: '年化',
        },
        clearStatus: {
          Repayment: '还款中',
          Overdue: '逾期未结清',
          Clearing_Overdue: '逾期清分中',
          Clearing_Apply: '申请结清中',
          PayOff: '正常结清',
          PayOff_Overdue: '逾期结清',
        },
        livePlatform: {
          10: '抖音',
          11: '快手',
          12: '拼多多',
          13: '腾讯视频号',
          14: '美团',
          15: '抖音小时达'
        },
      }
    },
    computed: {
      ...mapGetters(['permissions']),
      syncPermissions() {
        return this.permissions['biz_account_sync']
      },
    },
    watch: {},
    created() {
      this.getNamelist()
    },
    methods: {
      toPath(scope){
  this.$router.push(`/financing/order-detail?id=${scope.row.storeQuotaId}&billingMethod=${this.billingMethod[scope.row.billingMethod]}&customerRate=${scope.row.customerRate}&applyAmount=${scope.row.paymentAmount}&paymentDate=${scope.row.paymentDate}`)
  // this.$router.push(`/financing/order-detail?id=${scope.row.storeQuotaId}&customerRate=${scope.row.customerRate}&applyAmount=${scope.row.applyAmount}&paymentDate=${scope.row.paymentDate}`)
      },
      getNamelist() {
        return
        const api = 'bium-portal/controller/fin-channel/account/getEnterpriseNameList'
        this.doPost(api).then(({ data }) => {
          if (data) {
            this.option.column[1].dicData = data || []
          }
        })
      },
      handleSave() {
        this.isvisible = true
      },
      getList(page, params) {
        const api = 'bium-portal/controller/fin-ops/bill/store-quota/page'
        const { currentPage, pageSize } = page
        params = params
          ? params
          : {
              ...this.searchItmes,
            }
        if (params.accountNameDesc) {
          params.companyId = params.accountNameDesc
        }
        params.pageNum = page.currentPage
        params.pageSize = page.pageSize

        this.listLoading = true
        this.doPost(api, params).then(({ data }) => {
          if (data.result.records) {
            this.dataList = data.result.records || []
            this.page.total = data.result.total || 0;
            this.dataList = this.dataList.map((item, index)=>{
              item.orderId = item.id;
              item.id = item.id * (index + 1) * (index + 2);
              item.idLivePlatform = item.storeName + '/' + this.livePlatform[item.livePlatform]
              item.frozenAmount = (Number(item.customerRate)*100).toFixed(1) + '%/' + this.billingMethod[item.billMethod]
              item.financingRate = `${(Number(item.financingRate)*100).toFixed(2)}%`;
              if (item.isExpired == 0) {
                item.isExpiredStatus = '生效';
                item.showVoidOrderAction = true;
              } else {
                item.isExpiredStatus = '失效';
                item.showVoidOrderAction = false;
              }
              return item;
            })
            console.log('this.dataList', this.dataList);
            // this.dataList.forEach((item) => {
            //   item.idLivePlatform = item.storeName + '/' + this.livePlatform[item.livePlatform]
            //   item.frozenAmount = (Number(item.customerRate)*100).toFixed(1) + '%/' + this.billingMethod[item.billMethod]
            //   if (item.isExpired == 0) {
            //     item.isExpiredStatus = '生效'
            //   } else {
            //     item.isExpiredStatus = '失效'
            //   }
            //   // item.isExpired =
            //   //   item.isExpired == 0
            //   //     ? '生效'
            //   //     : '失效'
            // })
          }
          this.listLoading = false
        })
      },

      sizeChange(size) {
        this.page.pageSize = size
      },
      currentChange(current) {
        this.page.currentPage = current
      },
      handleFilter(param, done) {
        this.page.currentPage = 1
        this.searchItmes = param ? param : {}
        this.getList(this.page, param)
        done()
      },
      resetFilter() {
        this.page.currentPage = 1
        this.searchItmes = {}
        this.getList(this.page, this.searchItmes)
      },
      handleRefreshChange() {
        this.getList(this.page, this.searchItmes)
      },

      handleCloseDialog() {
        this.dialogVisible = false
      },
      handleCellClassName({ row, column, rowIndex, columnIndex }) {
        if ([7].includes(columnIndex)) {
          return 'yellow-cell'
        }
        return ''
      },

      queryBook(row, index) {
        this.listLoading = true
        doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
          this.listLoading = false
          this.handleRefreshChange()
        })
      },
      checkFlow() {
        doGet('/cap/account/checkFlow').then((res) => {
          this.handleRefreshChange()
        })
      },
      frozenBook(row, index) {
        this.listLoading = true
        doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
          this.listLoading = false
          this.handleRefreshChange()
        })
      },
      handleWidthdrawal() {
        this.$router.push({
          path: '/account/withdraw',
        })
      },
      onVoidOrder(obj) {
        let api = '/bium-portal/controller/fin-ops/store/store-quota/update';
        let params = {
          id: obj.orderId,
        }
        let that = this;
        that.doPost(api, params).then((res) => {
          if (res.data.errCode == 1000) {
            that.$message.success('操作成功!');
          }
          that.resetFilter();
        });
      }
    },
  }
  </script>

  <style lang="scss" scoped></style>
