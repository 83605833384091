<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud ref="crud" :option="option" :page.sync="page" :table-loading="listLoading" :data="dataList"
        :cell-class-name="handleCellClassName" @on-load="getList" @size-change="sizeChange"
        @current-change="currentChange" @search-change="handleFilter" @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template slot="menuRight">
          <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button> -->
        </template>
        <template slot="menuLeft">
          <!-- <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="handleWidthdrawal"
              >提现
            </el-button>
            <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="checkFlow"
              >流水核查
            </el-button> -->
        </template>

        <template slot="menu" slot-scope="scope">
          <el-button type="text" v-if="scope.row.productType != '1'"
            @click="toPath(scope)">查看详情</el-button>
          <!-- <el-button type="text" icon="el-icon-refresh"
                          @click="queryBook(scope.row, scope.index)">账户同步</el-button> -->
          <!-- <el-button
              v-if="scope.row.status == 1"
              type="text"
              icon="el-icon-view"
              @click="frozenBook(scope.row, scope.index)"
              >冻结</el-button
            >
            <el-button
              v-if="scope.row.status == 2"
              type="text"
              icon="el-icon-view"
              @click="frozenBook(scope.row, scope.index)"
              >解冻</el-button
            > -->
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import channelAdd from '@/views/channel/components/channelAdd'
import { mapGetters } from 'vuex'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchShowBtn: false,
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 200,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      label: '融资单号',
      prop: 'applyNo',
      width: 180,
      type: 'input',
      hide: true,
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      searchLabelWidth: 90,
      span: 24,
    },
    {
      label: '回款日期',
      prop: 'date',
      type: 'date',
      hide: true,
      searchRange: true,
      valueFormat: 'yyyy-MM-dd',
      search: true,
      searchSpan: 6,
      span: 12,
      width: 150,
    },
    {
      label: '店铺名称',
      prop: 'storeName',
      width: 180,
      type: 'input',
      hide: true,
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      searchLabelWidth: 90,
      span: 24,
    },
    {
      label: '回款批次号',
      prop: 'serialNo',
      width: 180,
      type: 'input',
      hide: true,
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      searchLabelWidth: 90,
      span: 24,
    },
    {
      label: '回款日期',
      prop: 'created',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '回款批次号',
      prop: 'serialNo',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },

    {
      label: '店铺名称/所属平台',
      prop: 'idLivePlatform',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '融资单号',
      prop: 'applyNo',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '结算金额',

      prop: 'amount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '待还本金',
      prop: 'principalAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '待还利息',
      prop: 'interestAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '平台服务费',
      prop: 'serviceFeeAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '渠道服务费',
      prop: 'channelFeeAmount',
      editDisplay: false,

      addDisplay: false,
      span: 12,
    },

    {
      label: '尾款金额',
      prop: 'releaseFeeAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,

    },
  ],
}

export default {
  name: 'TableUser',
  components: {
    channelAdd,
  },
  data() {
    return {
      finProcStatusList: [5,6,9,10],
      option: tableOption,
      searchItmes: {},
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      isvisible: false,
      dataList: [],
      listLoading: true,
      accountId: '',
      accountName: '',
      billingMethod: {
        Charge_Per_transaction: '单笔',
        Annualized: '年化',
      },
      clearStatus: {
        Repayment: '还款中',
        Overdue: '逾期未结清',
        Clearing_Overdue: '逾期清分中',
        Clearing_Apply: '申请结清中',
        PayOff: '正常结清',
        PayOff_Overdue: '逾期结清',
      },
      livePlatform: {
        10: '抖音',
        11: '快手',
        12: '拼多多',
        13: '腾讯视频号',
        14: '美团',
        15: '抖音小时达'
      }
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    syncPermissions() {
      return this.permissions['biz_account_sync']
    },
  },
  watch: {},
  created() {
    this.getNamelist()
  },
  methods: {
    isShow(status) {
      return this.finProcStatusList.find(item => item == status)
    },
    toPath(scope) {
      this.$router.push(`/finance/refund_detail?batchNo=${scope.row.batchNo}&storeQuotaId=${scope.row.storeQuotaId}&billingMethod=${this.billingMethod[scope.row.billingMethod]}&customerRate=${scope.row.customerRate}&applyAmount=${scope.row.paymentAmount}&paymentDate=${scope.row.paymentDate}`)
    },
    getNamelist() {
      const api = 'bium-portal/controller/fin-ops/account/getEnterpriseNameList'
      this.doPost(api).then(({ data }) => {
        if (data) {
          this.option.column[1].dicData = data || []
        }
      })
    },
    handleSave() {
      this.isvisible = true
    },
    invalid(id){
      const api = 'bium-portal/controller/fin-ops/apply/invalid'
      this.doPost(api, {
        applyId:id
      }).then(({ data }) => {
        if(data.success){
          this.resetFilter()
        }
      })
    },
    getList(page, params) {
      const api = 'bium-portal/controller/fin-ops/bill/deposit-clearing/page'
      const { currentPage, pageSize } = page
      params = params
        ? params
        : {
          ...this.searchItmes,
        }
      if (params.accountNameDesc) {
        params.companyId = params.accountNameDesc
      }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      params.startDate = params.date ? params.date[0] + ' 00:00:00' : ''
      params.endDate = params.date ? params.date[1] + ' 23:59:59' : ''
      this.listLoading = true
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
          this.dataList.forEach((item) => {
            item.idLivePlatform = item.storeName + '/' + this.livePlatform[item.livePlatform]
            item.frozenAmount = (Number(item.customerRate) * 100).toFixed(1) + '%/' + this.billingMethod[item.billingMethod]
            item.status = item.finProcStatus
            item.finProcStatus =
              item.finProcStatus == 5
                ? '待放款'
                : item.finProcStatus == 6
                  ? '放款中 '
                  : item.finProcStatus == 9
                    ? '放款成功'
                    : item.finProcStatus == 10
                      ? '申请失败'
                      : '申请中'
          })
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },

    handleCloseDialog() {
      this.dialogVisible = false
    },
    handleCellClassName({ row, column, rowIndex, columnIndex }) {
      if ([7].includes(columnIndex)) {
        return 'yellow-cell'
      }
      return ''
    },

    queryBook(row, index) {
      this.listLoading = true
      doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
        this.listLoading = false
        this.handleRefreshChange()
      })
    },
    checkFlow() {
      doGet('/cap/account/checkFlow').then((res) => {
        this.handleRefreshChange()
      })
    },
    frozenBook(row, index) {
      this.listLoading = true
      doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
        this.listLoading = false
        this.handleRefreshChange()
      })
    },
    handleWidthdrawal() {
      this.$router.push({
        path: '/account/withdraw',
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
