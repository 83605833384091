<template>
  <div class="selection-box">
    <section class="acc-container">
      <template v-for="item in pageDetail">
        <div :key="item.id">
          <!-- <el-divider class="title-divider" content-position="left">{{ item.title }}</el-divider> -->
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <div class="title-divider">{{item.title}}</div>
            <div v-if="item.canEdit">
              <div v-if="editType == item.key" style="display: flex;">
                <el-button
                  type="primary"
                  @click="onSave()"
                >
                  保存
                </el-button>
                <el-button
                  @click="onCancel()"
                >
                  取消
                </el-button>
              </div>
              <i class="el-icon-edit-outline" v-else @click="onEdit(item.key)"></i>
            </div>
          </div>
          <div class="jg-acc">
            <div class="card-box">
              <div v-for="json in item.data" :key="json.value" class="card-item">
                <div class="item_label">{{ json.label }}：</div>
                <div v-if="editType == item.key && json.canEdit">
                  <el-input
                    v-if="json.type == 'input'"
                    v-model="form[item.key][json.value]"
                  />
                  <el-date-picker
                    v-if="json.type == 'date'"
                    v-model="form[item.key][json.value]"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  />
                  <el-select
                    v-if="json.type == 'select'"
                    v-model="form[item.key].livePlatform"
                    placeholder="请选择店铺所属平台"
                    clearable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in livePlatformList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
                <div v-else>{{ detailData[item.key][json.value] }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--      <el-divider class="title-divider" content-position="left">店铺信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in shopformation" :key="index" class="card-item">
                  <div class="item_label">
                    {{ item.name }}:
                  </div>
                  <div>
                    <span v-if="item.value == 'livePlatform'">
                      <span v-if="shopform[item.value] == 10">抖音</span>
                      <span v-if="shopform[item.value] == 11">快手</span>
                      <span v-if="shopform[item.value] == 12">拼多多</span>
                      <span v-if="shopform[item.value] == 13">腾讯视频号</span>
                      <span v-if="shopform[item.value] == 14">美团</span>
                    </span>
                    <span v-else-if="item.value == 'storeStatusDesc'">
                      {{ storeStatusDesc }}
                    </span>
                    <span v-else-if="item.value == 'virtualAcctStatusDesc'">
                      {{ virtualAcctStatusDesc }}
                    </span>
                    <span v-else> {{ shopform[item.value] }}</span>
                  </div>
                </div>

              </div>

            </div>
            &lt;!&ndash;      <div v-if="form.productType == 0">
                    <el-divider class="title-divider" content-position="left">订单信息</el-divider>
                    <div>
                      <div class="search">
                        <div class="search_item">
                          <label class="search_label">订单号：</label>
                          <el-input v-model="applyNo" class="search_input" />
                        </div>
                        <el-button type="primary" @click="page.currentPage = 1, getStoreClearPage()">
                          筛选
                        </el-button>
                        <el-button type="primary" @click="applyNo = '', page.currentPage = 1, getStoreClearPage()">
                          重置
                        </el-button>
                      </div>
                      <div v-if="orderList.length > 0" class="form_table">
                        <div v-for=" item in orderList" :key="item.applyNo" class="table_list">
                          <div class="list_title">
                            <div>订单号：{{ item.applyNo }}</div>
                            <div>{{ item.created }}</div>
                          </div>
                          <div class="list_title">
                            <div class="payAmount">放款金额：{{ item.paymentAmount }}元</div>
                            <div class="state">状态：
                              <span v-if="item.status == 'Repayment'">还款中</span>
                              <span v-else-if="item.status == 'PayOff'" style="color:#8bdd0e">正常结清</span>
                              <span v-else-if="item.status == 'Overdue'" style="color:red">逾期未结清</span>
                              <span v-else-if="item.status == 'Overdue_Clearing'" style="color:red">逾期清分中</span>
                              <span v-else-if="item.status == 'PayOff_Overdue'" style="color:#8bdd0e">逾期结清</span>
                            </div>
                          </div>
                          <div class="list_content">
                            <div class="list_item">
                              融资订单数：{{ item.orderCount }}笔
                            </div>
                            <div class="list_item">
                              费率：{{ (Number(item.customerRate) * 100).toFixed(4) }}%/单笔
                            </div>
                            <div class="list_item">
                              折扣率：{{ (Number(item.discountRate) * 100).toFixed(2) }}%
                            </div>
                            <div class="list_item">
                              剩余应还本金：{{ item.pendingPrincipal }}元
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="dataValue">
                        暂无订单数据
                      </div>
                      <div v-if="orderList.length > 0" class="page">
                        <el-pagination
                          :current-page.sync="page.currentPage"
                          :page-size="10"
                          :total="page.total"
                          layout="prev, pager, next, jumper"
                          @current-change="getStoreClearPage"
                        />
                      </div>

                    </div>
                    <el-divider class="title-divider" content-position="left">额度信息</el-divider>
                    <div class="jg-acc">
                      &lt;!&ndash;开户成功，有数据&ndash;&gt;
                      <div class="card-box">
                        <div v-for="(item, index) in StoreAmountInfo" :key="index" class="card-item">
                          <div class="item_label">
                            {{ item.name }}:
                          </div>
                          <div>
                            <span v-if="item.value == 'updateCredit'">{{
                              StoreAmountForm[item.value] ? '是' : '否'
                            }}</span>
                            <span v-else-if="item.value == 'financingRatio'">{{
                              StoreAmountForm[item.value] ?
                                (Number(StoreAmountForm[item.value])
                                  * 100).toFixed(2) : '&#45;&#45;'
                            }} {{
                              StoreAmountForm[item.value] ? item.unit : ''
                            }}</span>
                            <span v-else-if="StoreAmountForm[item.value]">{{ StoreAmountForm[item.value] }} {{
                              item.unit
                            }}</span>
                            <span v-else>&#45;&#45;</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>&ndash;&gt;
            <el-divider class="title-divider" content-position="left">企业营业信息</el-divider>
            <div class="jg-acc">
              &lt;!&ndash;开户成功，有数据&ndash;&gt;
              <div class="card-box">
                <div v-for="(item, index) in companyInfo" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}：</div>
                  <div>{{ item.value }}</div>
                </div>

              </div>
            </div>
            <el-divider class="title-divider" content-position="left">费率方案</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div class="card-item">
                  <div class="item_label">所属产品:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">资金方:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">企业归属:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">融资折扣率:</div>
                  <div>{{ form.dailyRepaymentRatio }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">店铺平均回款天数:</div>
                  <div>{{ form.customerRate1 }}%</div>
                </div>
                <div class="card-item">
                  <div class="item_label">对客费率:</div>
                  <div>{{ form.paymentDays }}天</div>
                </div>
                <div class="card-item">
                  <div class="item_label">对客费率方案:</div>
                  <div>{{ form.channelName ? form.channelName : '直客' }}</div>
                </div>
              </div>
            </div>
            <el-divider class="title-divider" content-position="left">店铺额度信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div class="card-item">
                  <div class="item_label">所属企业:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">店铺上限授信金额:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">店铺已用额度:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">企业上限授信额度:</div>
                  <div>{{ form.dailyRepaymentRatio }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">企业已用额度:</div>
                  <div>{{ form.customerRate1 }}%</div>
                </div>
              </div>
            </div>
            <el-divider class="title-divider" content-position="left">店铺融资信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div class="card-item">
                  <div class="item_label">所属产品:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">店铺总融资笔数:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">店铺待还笔数:</div>
                  <div>{{ form.fundingName }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">待还本金:</div>
                  <div>{{ form.dailyRepaymentRatio }}</div>
                </div>
                <div class="card-item">
                  <div class="item_label">待还利息:</div>
                  <div>{{ form.customerRate1 }}%</div>
                </div>
                <div class="card-item">
                  <div class="item_label">待还服务费:</div>
                  <div>{{ form.paymentDays }}天</div>
                </div>
              </div>
            </div>
            &lt;!&ndash;      <div :class="{ mb20: list.length === 0 }" />
                  <el-divider class="title-divider" content-position="left">法人信息</el-divider>
                  <div class="jg-acc">
                    &lt;!&ndash;开户成功，有数据&ndash;&gt;
                    <div class="card-box">
                      <div class="card-item">
                        <div class="item_label">法人姓名:</div>
                        <div>{{ form.legalName }}</div>
                      </div>

                      <div class="card-item">
                        <div class="item_label">法人身份证号:</div>
                        <div>{{ form.legalCard }}</div>
                      </div>
                      <div class="card-item">
                        <div class="item_label">身份证有效期:</div>
                        <div>{{ form.legalDuration }}</div>
                      </div>
                      <div class="card-item">
                        <div class="item_label">法人邮箱:</div>
                        <div>{{ form.legalMail }}</div>
                      </div>
                      <div class="card-item">
                        <div class="item_label">法人手机号码:</div>
                        <div>{{ form.legalMobile }}</div>
                      </div>

                      <div class="card-item">
                        <div class="item_label">民族:</div>
                        <div>{{ form.legalEthnic }}</div>
                      </div>
                    </div>
                  </div>
                  <el-divider v-if="form.authorizationCard" class="title-divider" content-position="left">授权人信息</el-divider>
                  <div v-if="form.authorizationCard" class="jg-acc">
                    &lt;!&ndash;开户成功，有数据&ndash;&gt;
                    <div class="card-box">
                      <div class="card-item">
                        <div class="item_label">授权人姓名:</div>
                        <div>{{ form.authorizationName }}</div>
                      </div>

                      <div class="card-item">
                        <div class="item_label">授权人身份证号:</div>
                        <div>{{ form.authorizationCard }}</div>
                      </div>
                      <div class="card-item">
                        <div class="item_label">身份证有效期:</div>
                        <div>{{ form.authorizationDuration }}</div>
                      </div>
                      <div class="card-item">
                        <div class="item_label">授权人邮箱:</div>
                        <div>{{ form.authorizationMail }}</div>
                      </div>
                      <div class="card-item">
                        <div class="item_label">授权人电话:</div>
                        <div>{{ form.authorizationMobile }}</div>
                      </div>
                      <div class="card-item">
                        <div class="item_label">民族:</div>
                        <div>{{ form.authorizationEthnic }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="Fileform.length">
                    <imgdialog :code="form.enterpriseId" :file-arr="Fileform" />
                    <filedialog :code="form.enterpriseId" :file-arr="Fileform" />

                  </div>&ndash;&gt;-->
    </section>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import imgdialog from '@/views/operate/components/imgdialog.vue';
import filedialog from '@/views/operate/components/filedialog.vue';

export default defineComponent({
  name: 'clientDetail',
  components: {
    imgdialog,
    filedialog
  },
  data() {
    return {
      editType: '',
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10
      },
      Fileform: [],
      applyNo: '',
      residueAmountType: true,
      isvisible: false,
      form: {},
      list: [{}],
      listLoading: false,
      shopform: {},
      orderList: [],
      virtualAcctStatusDesc: '',
      storeStatusDesc: '',
      StoreAmountForm: {},
      StoreAmountInfo: [
        {
          name: '用户平均回款天数',
          value: 'avgTurnoverDays',
          unit: '天'
        }, {
          name: '店铺当前可用额度',
          value: 'availableAmount',
          unit: '元'
        }, {
          name: '店铺已用额度',
          value: 'usedAmount',
          unit: '元'
        }, {
          name: '折扣率',
          value: 'financingRatio',
          unit: '%'
        }, {
          name: '是否需要更新征信',
          value: 'updateCredit',
          unit: ''
        }
      ],
      // 店铺信息
      shopformation: [
        {
          name: '店铺名称',
          value: 'name'
        }, {
          name: '店铺所属平台',
          value: 'livePlatform'
        }, {
          name: '店铺ID',
          value: 'storeCode'
        }, {
          name: '店铺所属产品',
          value: 'productTypeDesc'
        }, {
          name: '店铺数据更新时间',
          value: ''
        }, {
          name: '店铺账号状态',
          value: 'storeStatusDesc'
        }, {
          name: '店铺账户状态',
          value: 'virtualAcctStatusDesc'
        }
      ],
      // 客户信息
      customerInfo: [
        {
          name: '客户名称',
          value: 'enterpriseName'
        }, {
          name: '产品号',
          value: 'productType'
        }, {
          name: '企业编号',
          value: 'enterpriseCode'
        }, {
          name: '联系方式',
          value: 'mobile'
        }, {
          name: '状态',
          value: 'enterpriseStatus'
        }
      ],
      companyInfo: [
        {
          name: '企业名称',
          value: '--'
        },
        {
          name: '社会信用代码',
          value: '--'
        },
        {
          name: '企业地址信息',
          value: '--'
        },
        {
          name: '营业执照有效期',
          value: '--'
        }
      ],
      detailData: {
        store: {},
        enterprise: {},
        ratePlan: {},
        creditQuota: {},
        financing: {}
      },
      controlled: {
        un_controlled: '未管控',
        controlled: '已管控'
      },
      form: {
        store: {
          name: '',
          livePlatform: '',
          storeCode: '',
          businessStart: '',
          businessCategory: '',
        },
        creditQuota: {
          storeUpperLimit: ''
        },
      },
      pageDetail: [
        {
          title: '店铺信息',
          key: 'store',
          canEdit: true,
          data: [
            { label: '店铺名称', value: 'name', canEdit: true, type: 'input' },
            { label: '店铺所属平台', value: 'livePlatformDesc', canEdit: true, type: 'select' },
            { label: '店铺ID', value: 'storeCode', canEdit: true, type: 'input' },
            { label: '店铺所属产品', value: 'productTypeDesc' },
            { label: '店铺数据更新时间', value: 'dataUpdated' },
            { label: '店铺账号状态', value: 'storeStatus' },
            { label: '店铺账户状态', value: 'virtualAcctStatus' },
            { label: '店铺经营开始时间', value: 'businessStart', canEdit: true, type: 'date' },
            { label: '店铺经营类目', value: 'businessCategory', canEdit: true, type: 'input' }
          ]
        }, {
          title: '企业营业信息',
          key: 'enterprise',
          data: [
            { label: '企业名称', value: 'name' },
            { label: '社会信用代码', value: 'certNo' },
            { label: '企业地址信息', value: 'address' },
            { label: '营业执照有效期', value: 'issuePeriod' }
          ]
        }, {
          title: '费率方案',
          key: 'ratePlan',
          data: [
            { label: '所属产品', value: 'productTypeDesc' },
            { label: '资金方', value: 'fundingName' },
            { label: '企业归属', value: 'channelName' },
            // { label: '每日还款比例', value: 'repayRateStr' },
            { label: '融资折扣率', value: 'discountRate' },
            { label: '店铺平均回款天数', value: 'avgTurnoverDays' },
            { label: '对客费率', value: 'customerRate' },
            { label: '对客费率方案', value: '' }
          ]
        }, {
          title: '店铺额度信息',
          key: 'creditQuota',
          canEdit: true,
          data: [
            { label: '所属产品', value: 'productTypeDesc' },
            { label: '店铺上限授信金额', value: 'storeUpperLimit', canEdit: true, type: 'input' },
            { label: '店铺已用额度', value: 'storeInTransit' },
            { label: '企业上限授信额度', value: 'enterpriseUpperLimit' },
            { label: '企业已用额度', value: 'enterpriseInTransit' }
          ]
        }, {
          title: '店铺融资信息',
          key: 'financing',
          data: [
            { label: '所属产品', value: 'productTypeDesc' },
            { label: '店铺总融资笔数', value: 'financingCount' },
            { label: '店铺待还笔数', value: 'inTransitCount' },
            { label: '待还本金', value: 'inTransitPrincipal' },
            { label: '待还利息', value: 'inTransitInterest' },
            { label: '待还服务费', value: 'inTransitServiceFee' }
          ]
        }
      ],
      livePlatformToText: {
        '10': '抖音',
        '11': '快手',
        '12': '拼多多',
        '13': '腾讯视频号',
        '14': '美团',
        '15': '抖音小时达'
      },
      livePlatformDescToId: {
        '抖音': '10',
        '快手': '11',
        '拼多多': '12',
        '腾讯视频号': '13',
        '美团': '14',
        '抖音小时达': '15'
      },
      livePlatformList: [
        {
          label: '抖音',
          value: '10'
        },
        {
          label: '快手',
          value: '11'
        },
        {
          label: '拼多多',
          value: '12'
        },
        {
          label: '腾讯视频号',
          value: '13'
        },
        {
          label: '美团',
          value: '14'
        },
        {
          label: '抖音小时达',
          value: '15'
        }
      ],
    };
  },
  created() {
    // this.getDetails();
    this.getPageDetail();
  },
  mounted() {
  },

  methods: {
    // 获取店铺管控信息
    getPageDetail() {
      this.doPost('/bium-portal/controller/fin-ops/store/view-detail/get', {
        id: this.$route.query.shopid
        // id: 20
      }).then(({ data }) => {
        this.detailData = data.result;
        this.form.store.name = data.result.store.name;
        this.form.store.livePlatform = this.livePlatformDescToId[data.result.store.livePlatformDesc];
        this.form.store.storeCode = data.result.store.storeCode;
        this.form.store.businessStart = data.result.store.businessStart || '';
        this.form.store.businessCategory = data.result.store.businessCategory;
        this.form.creditQuota.storeUpperLimit = data.result.creditQuota.storeUpperLimit;
        this.detailData.store.storeStatus = this.controlled[this.detailData.store.storeStatus];
        this.detailData.store.virtualAcctStatus = this.controlled[this.detailData.store.virtualAcctStatus];
      });
    }, // 获取店铺管控信息
    getshopData() {
      this.doPost('/bium-portal/controller/fin-ops/store/approval/get', {
        storeId: this.$route.query.shopid
      }).then(({ data }) => {
        this.storeStatusDesc = data.result.storeStatusDesc;
        this.virtualAcctStatusDesc = data.result.virtualAcctStatusDesc;
      });
    },
    // 获取订单信息
    getStoreClearPage() {
      this.doPost('/bium-portal/controller/fin-ops/store/getStoreClearPage', {
        storeCode: this.$route.query.storeCode,
        pageSize: 10,
        applyNo: this.applyNo,
        pageNum: this.page.currentPage
      }).then(({ data }) => {
        this.orderList = data.result.records;
        this.page.total = data.result.total;
      });
    },
    // 获取店铺额度
    getStoreAmount() {
      this.doPost('/bium-portal/controller/fin-ops/store/getStoreAmount', {
        id: this.$route.query.shopid
      }).then(({ data }) => {
        this.StoreAmountForm = data.result;
      });
    },
    // 获取对客费率
    getRatePlanDetail() {
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlanDetail', {
        storeId: this.$route.query.shopid
      }).then(res => {
        this.form.customerRate1 = (Number(res.data.result.custRateDetailDTOList[0].customerRate) * 100).toFixed(4);
        this.$forceUpdate();
      });
    },
    // 获取店铺信息
    getShop() {
      this.doPost('bium-portal/controller/fin-ops/store/getStoreInfoById', {
        id: this.$route.query.shopid
      }).then(res => {
        this.shopform = res.data.result;
      });
    },
    // 获取上传图片，文件信息
    getFile() {
      this.doPost('bium-portal/controller/fin-ops/attach/list', {
        enterpriseId: this.$route.query.id
      }).then(res => {
        this.Fileform = res.data.result;
      });
    },
    // 查看费率
    toConfigStandardPlan(form) {
      console.log(form, 'qqqq');
      const comp = () => import('../expense/components-plan/plan-view.vue');
      const dataItem = {
        storeId: this.$route.query.shopid
      };
      this.$modalDialog(comp, { dataItem });
    },
    // 获取企业等详情
    getDetails() {
      this.doPost('bium-portal/controller/fin-ops/enterprise/getEnterpriseDetailById', {
        id: this.$route.query.id
      }).then(res => {
        this.form = res.data.result;
        const data = res.data.result;
        if (this.form.dailyRepaymentRatio) {
          this.form.dailyRepaymentRatio = this.form.dailyRepaymentRatio.match(/(\S*)%/)[1];
          this.form.dailyRepaymentRatio = `${(Number(this.form.dailyRepaymentRatio) * 100).toFixed(2)}%`;
        } else {
          this.form.dailyRepaymentRatio = '0.00%';
        }
        this.companyInfo[0].value = data.name ? data.name : '';
        this.companyInfo[1].value = data.creditCode ? data.creditCode : '';
        this.companyInfo[2].value = data.address ? data.address : '';
        this.companyInfo[3].value = data.validDate ? data.establishmentDate + '至' + data.validDate : '';
        if (this.$route.query.id && this.$route.query.shopid && this.$route.query.storeCode) {
          this.getRatePlanDetail();
          this.getShop();
          this.getFile();
          this.getshopData();
          if (this.form.productType == 0) {
            this.getStoreAmount();
            this.page.currentPage = 1;
            this.getStoreClearPage();
          }
        }
      });
    },
    onEdit(key) {
      this.editType = key;
    },
    onCancel() {
      this.editType = '';
      this.getData();
    },
    onSave() {
      if (this.editType == 'store') {
        let params = {
          id: this.detailData.store.id,
          name: this.form.store.name,
          livePlatform: this.form.store.livePlatform,
          storeCode: this.form.store.storeCode,
        }
        let extJson = {
          operateTime: this.form.store.businessStart,
          operateType: this.form.store.businessCategory,
        }
        params.extJson = JSON.stringify(extJson);
        console.log('extJson', extJson);
        this.doPost('/bium-portal/controller/fin-ops/store/modifyInfo',
          params
        ).then(({ data }) => {
          this.$message.success('保存成功');
          this.editType = '';
          this.getPageDetail();
        });
      }
      if (this.editType == 'creditQuota') {
        let params = {
          id: this.detailData.store.id,
          creditAmount: this.form.creditQuota.storeUpperLimit
        }
        this.doPost('/bium-portal/controller/fin-ops/store/modifyCreditAmount', params)
          .then(res=>{
            this.$message.success('保存成功');
            this.editType = '';
            this.getPageDetail();
          });
      }
    },
  }
});
</script>

<style lang="scss" scoped>

.acc-container {
  height: auto;
  min-height: 60px;

  .acc-tip {
    text-align: center;
  }

  .dis-act {
    color: #909399;
    background-color: #eee;
    border-color: #eee;
    cursor: not-allowed;
    margin: 0;

    i {
      font-style: normal;
      color: #f00;
    }
  }

  /deep/ .el-loading-mask {
    top: -8px;
  }
}
</style>
