var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("文件信息\n    ")]
          ),
          _c("div", { staticClass: "jg-acc" }, [
            _c(
              "div",
              { staticClass: "card-box flexBox" },
              _vm._l(_vm.fileList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "file-item flexBox-item" },
                  [
                    _c("img", {
                      staticClass: "flexBox-item-img",
                      attrs: { src: _vm.fileObj[item.value] },
                    }),
                    _c("div", { staticClass: "maskView" }, [
                      _c(
                        "div",
                        {
                          staticClass: "maskView_action",
                          on: {
                            click: function ($event) {
                              return _vm.showFile(item)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-picture-outline" }),
                          _vm._v("\n              查看大图\n            "),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "maskView_action" },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              refInFor: true,
                              attrs: {
                                action: "http-request",
                                name: item.value,
                                limit: 1,
                                "file-list": _vm.files,
                                "show-file-list": false,
                                "list-type": "text",
                                "http-request": _vm.httpRequest,
                              },
                            },
                            [
                              _c("div", { staticClass: "maskView_action" }, [
                                _c("i", { staticClass: "el-icon-upload2" }),
                                _vm._v(
                                  "\n                  重新上传\n                "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "查看图片",
            width: "600px",
            top: "100px",
            visible: _vm.isvisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isvisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex-c" }, [
            _c("img", {
              staticStyle: { width: "500px", height: "400px" },
              attrs: { src: _vm.fileUrl, alt: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }