<template>
    <div class="user">
        <basic-container class="full-condition">
            <avue-crud ref="crud" :option="option" :page.sync="page" :table-loading="listLoading" :data="dataList"
                :cell-class-name="handleCellClassName" @on-load="getList" @size-change="sizeChange"
                @current-change="currentChange" @search-change="handleFilter" @search-reset="resetFilter"
                @refresh-change="handleRefreshChange">
                <template slot="menuRight">
                    <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button> -->
                </template>
                <template slot="menuLeft">
                  <el-button
                    type="primary"
                    icon="el-icon-download"
                    @click="exportExcel"
                  >
                    导出
                  </el-button>
                    <!-- <el-button
                class="filter-item"
                type="primary"
                icon="icon-YUAN"
                @click="handleWidthdrawal"
                >提现
              </el-button>
              <el-button
                class="filter-item"
                type="primary"
                icon="icon-YUAN"
                @click="checkFlow"
                >流水核查
              </el-button> -->
                </template>

                <template slot="menu" slot-scope="scope">
                    <el-button type="text" @click="toPath(scope)">查看详情</el-button>
                    <!-- <el-button type="text" icon="el-icon-refresh"
                            @click="queryBook(scope.row, scope.index)">账户同步</el-button> -->
                    <!-- <el-button
                v-if="scope.row.status == 1"
                type="text"
                icon="el-icon-view"
                @click="frozenBook(scope.row, scope.index)"
                >冻结</el-button
              >
              <el-button
                v-if="scope.row.status == 2"
                type="text"
                icon="el-icon-view"
                @click="frozenBook(scope.row, scope.index)"
                >解冻</el-button
              > -->
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
import channelAdd from '@/views/channel/components/channelAdd';
import excel from '@/utils/excel';
import { mapGetters } from 'vuex';

const tableOption = {
    searchMenuSpan: 24,
    searchLabelWidth: 80,
    searchMenuPosition: 'center',
    searchIndex: 3,
    searchShowBtn: false,
    border: false,
    showSummary: false,
    printBtn: false,
    index: false,
    indexLabel: '序号',
    stripe: true,
    menualign: 'left',
    menuWidth: 200,
    searchIndex: 3,
    searchIcon: false,
    searchShowBtn: false,
    labelWidth: 100,
    editBtn: false,
    delBtn: false,
    align: 'left',
    addBtn: false,
    column: [
        {
            fixed: true,
            label: 'id',
            prop: 'id',
            span: 24,
            hide: true,
            editDisplay: false,
            addDisplay: false,
        },
        {
            label: '融资单号',
            prop: 'applyNo',
            width: 180,
            type: 'input',
            hide: true,
            editDisplay: true,
            addDisplay: false,
            search: true,
            searchSpan: 6,
            searchLabelWidth: 90,
            span: 24,
        },
        {
            label: '是否逾期',
            prop: 'isOverdue',
            type: 'select',
            search: true,
            hide: true,
            dicData: [
                { label: '是', value: true },
                { label: '否', value: false },
            ],
        },
        {
            label: '还款类型',
            prop: 'amountType',
            type: 'select',
            search: true,
            hide: true,
            dicData: [
                { label: '本金', value: 'PRINCIPAL' },
                { label: '利息', value: 'INTEREST' },
            ],
        },
        {
            label: '还款时间',
            prop: 'repaymentDate',
            type: 'date',
            hide: true,
            searchRange: true,
            valueFormat: 'yyyy-MM-dd',
            search: true,
            searchSpan: 6,
            span: 12,
            width: 150,
        },
        {
            label: '还款流水号',
            prop: 'repayNo',
            width: 180,
            type: 'input',
            hide: true,
            editDisplay: true,
            addDisplay: false,
            search: true,
            searchSpan: 6,
            searchLabelWidth: 90,
            span: 24,
        },

        {
            label: '还款时间',
            prop: 'repayDate',
            editDisplay: false,
            width: 200,
            addDisplay: false,
            span: 12,
        },
        {
            label: '还款流水号',
            prop: 'repayNo',
            type: 'input',
            width: 200,
        },
        {
            width: 200,
            label: '还款类型',
            prop: 'amountType',
            editDisplay: false,
            addDisplay: false,
            span: 12,
        },
        {
            width: 200,
            label: '还款金额',
            prop: 'repayAmount',
            editDisplay: false,
            addDisplay: false,
            span: 12,
        },
        {
            label: '还款状态',
            prop: 'transferStatus',
            width: 200,
            editDisplay: false,
            addDisplay: false,
            span: 12,
        },
        {
            label: '批次号',
            prop: 'batchNo',
            editDisplay: false,
            width: 200,
            addDisplay: false,
            span: 12,
        },
        {
            label: '银行/机构流水号',
            prop: 'sourceTxnId',
            editDisplay: false,
            width: 200,
            addDisplay: false,
            span: 12,
        },
        {
            label: '店铺名称/所属平台',
            prop: 'storeName',
            width: 200,
            type: 'input',
            editDisplay: false,
            addDisplay: false,
            span: 12,
        },
        {
          label: '店铺名称',
          prop: 'storeName',
          editDisplay: false,
          addDisplay: false,
          type: 'input',
          hide: true,
          search: true,
          span: 12,
        },
        {
            label: '融资单号',
            prop: 'applyNo',
            editDisplay: false,
            width: 200,
            addDisplay: false,
            span: 12,
        },
        {
            label: '融资金额',
            width: 200,
            prop: 'applyAmount',
            editDisplay: false,
            addDisplay: false,
            span: 12,
        },
        {
            label: '是否逾期',
            prop: 'status',
            editDisplay: false,
            addDisplay: false,
            span: 12,
            width: 200,
        },


    ],
}

export default {
    name: 'TableUser',
    components: {
        channelAdd,
    },
    data() {
        return {
            option: tableOption,
            searchItmes: {},
            dialogVisible: false,
            page: {
                total: 0, // 总页数
                currentPage: 1,
                pageSize: 10,
            },
            isvisible: false,
            dataList: [],
            listLoading: true,
            accountId: '',
            accountName: '',
            billingMethod: {
                Charge_Per_transaction: '单笔',
                Annualized: '年化',
            },
            clearStatus: {
                Repayment: '还款中',
                Overdue: '逾期未结清',
                Clearing_Overdue: '逾期清分中',
                Clearing_Apply: '申请结清中',
                PayOff: '正常结清',
                PayOff_Overdue: '逾期结清',
            },
            livePlatform: {
                10: '抖音',
                11: '快手',
                12: '拼多多',
                13: '腾讯视频号',
                14: '美团',
                15: '抖音小时达'
            },
            transferStatus: {
                Pending: '还款中',
                Processing: '还款中',
                Repayment: '还款中',
                Succeeded: '还款成功',
                Failed: '还款失败',
                Cancelled: '还款失败',
                retry: '待重试',
            },
            amountType: {
                PRINCIPAL: '本金',
                INTEREST: '利息'
            }
        }
    },
    computed: {
        ...mapGetters(['permissions']),
        syncPermissions() {
            return this.permissions['biz_account_sync']
        },
    },
    watch: {},
    created() {
        this.getNamelist()
    },
    methods: {
        exportExcel() {
          const api = 'bium-portal/controller/fin-ops/bill/repay/page'
          const params = {
            ...this.searchItmes
          }
          if (params.accountNameDesc) {
            params.companyId = params.accountNameDesc
          }
          params.pageNum = 1
          params.pageSize = 99999
          params.repaymentStartDate = params.repaymentDate ? params.repaymentDate[0] + ' 00:00:00' : ''
          params.repaymentEndDate = params.repaymentDate ? params.repaymentDate[1] + ' 23:59:59' : ''
          delete params.settlementDate;
          this.doPost(api, params).then(({ data }) => {
            let records = data.result.records;
            records = records.map(item=>{
              item.idLivePlatform = item.storeName + '/' + this.livePlatform[item.livePlatform]
              item.amountType = this.amountType[item.amountType]
              item.transferStatus = this.transferStatus[item.transferStatus]
              item.frozenAmount = item.customerRate * 100 + '%/' + this.billingMethod[item.billingMethod]
              if (item.status == 'Overdue' || item.status == 'Clearing_Overdue' || item.status == 'PayOff_Overdue') {
                item.status = '是'
              } else {
                item.status = '否'
              }
              return item;
            })
            const list = {
              title: ['还款时间', '还款流水号', '还款类型', '还款金额', '还款状态', '批次号', '银行/机构流水号', '店铺名称/所属平台', '融资单号', '融资金额', '是否逾期'],
              key: ['repayDate', 'repayNo', 'amountType', 'repayAmount', 'transferStatus', 'batchNo', 'sourceTxnId', 'storeName', 'applyNo', 'applyAmount', 'status'],
              data: records, // 数据源
              autoWidth: true,
              filename: '还款明细'
            }
            excel.exportArrayToExcel(list)
          })
        },
        toPath(scope) {
            this.$router.push(
                `/finance/repayment_detail?id=${scope.row.storeQuotaId}&batchNo=${scope.row.batchNo}&transferStatus=${scope.row.transferStatus}&repayDate=${scope.row.repayDate}&repayNo=${scope.row.repayNo}&status=${scope.row.status}&repayAmount=${scope.row.repayAmount}`)
        },
        getNamelist() {
            const api = 'bium-portal/controller/fin-ops/account/getEnterpriseNameList'
            this.doPost(api).then(({ data }) => {
                if (data) {
                    this.option.column[1].dicData = data || []
                }
            })
        },
        handleSave() {
            this.isvisible = true
        },
        getList(page, params) {
            const api = 'bium-portal/controller/fin-ops/bill/repay/page'
            const { currentPage, pageSize } = page
            params = params
                ? params
                : {
                    ...this.searchItmes,
                }
            if (params.accountNameDesc) {
                params.companyId = params.accountNameDesc
            }
            params.pageNum = page.currentPage
            params.pageSize = page.pageSize
            params.repaymentStartDate = params.repaymentDate ? params.repaymentDate[0] + ' 00:00:00' : ''
            params.repaymentEndDate = params.repaymentDate ? params.repaymentDate[1] + ' 23:59:59' : ''
            this.listLoading = true
            this.doPost(api, params).then(({ data }) => {
                if (data.result.records) {
                    this.dataList = data.result.records || []
                    this.page.total = data.result.total || 0
                    this.dataList.forEach((item) => {
                        item.idLivePlatform = item.storeName + '/' + this.livePlatform[item.livePlatform]
                        item.amountType = this.amountType[item.amountType]
                        item.transferStatus = this.transferStatus[item.transferStatus]
                        item.frozenAmount = item.customerRate * 100 + '%/' + this.billingMethod[item.billingMethod]
                        if (item.status == 'Overdue' || item.status == 'Clearing_Overdue' || item.status == 'PayOff_Overdue') {
                            item.status = '是'
                        } else {
                            item.status = '否'
                        }
                    })
                }
                this.listLoading = false
            })
        },

        sizeChange(size) {
            this.page.pageSize = size
        },
        currentChange(current) {
            this.page.currentPage = current
        },
        handleFilter(param, done) {
            this.page.currentPage = 1
            this.searchItmes = param ? param : {}
            this.getList(this.page, param)
            done()
        },
        resetFilter() {
            this.page.currentPage = 1
            this.searchItmes = {}
            this.getList(this.page, this.searchItmes)
        },
        handleRefreshChange() {
            this.getList(this.page, this.searchItmes)
        },

        handleCloseDialog() {
            this.dialogVisible = false
        },
        handleCellClassName({ row, column, rowIndex, columnIndex }) {
            if ([7].includes(columnIndex)) {
                return 'yellow-cell'
            }
            return ''
        },

        queryBook(row, index) {
            this.listLoading = true
            doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
                this.listLoading = false
                this.handleRefreshChange()
            })
        },
        checkFlow() {
            doGet('/cap/account/checkFlow').then((res) => {
                this.handleRefreshChange()
            })
        },
        frozenBook(row, index) {
            this.listLoading = true
            doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
                this.listLoading = false
                this.handleRefreshChange()
            })
        },
        handleWidthdrawal() {
            this.$router.push({
                path: '/account/withdraw',
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>
