<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :cell-class-name="handleCellClassName"
        :data="dataList"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >
        <template slot="menuRight">
          <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button> -->
        </template>
        <template slot="menuLeft">
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="exportExcel"
          >
            导出
          </el-button>
          <!-- <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="handleWidthdrawal"
              >提现
            </el-button>
            <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="checkFlow"
              >流水核查
            </el-button> -->
        </template>

        <template slot="menu" slot-scope="scope">
          <el-button
            v-if="scope.row.finProcStatus == '放款成功' && scope.row.productType == '0'"
            type="text"
            @click="toPath(scope)"
          >查看详情
          </el-button>
          <el-popconfirm title="作废后将不可恢复，请确认" @confirm="invalid(scope.row.applyId)">
            <el-button v-if="!isShow(scope.row.status)" slot="reference" type="text">作废</el-button>
          </el-popconfirm>
          <!-- <el-button type="text" icon="el-icon-refresh"
                          @click="queryBook(scope.row, scope.index)">账户同步</el-button> -->
          <!-- <el-button
              v-if="scope.row.status == 1"
              type="text"
              icon="el-icon-view"
              @click="frozenBook(scope.row, scope.index)"
              >冻结</el-button
            >
            <el-button
              v-if="scope.row.status == 2"
              type="text"
              icon="el-icon-view"
              @click="frozenBook(scope.row, scope.index)"
              >解冻</el-button
            > -->
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import channelAdd from '@/views/channel/components/channelAdd';
import excel from '@/utils/excel';
import { mapGetters } from 'vuex';

const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchShowBtn: false,
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 200,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false
    },
    {
      label: '融资单号',
      prop: 'applyNo',
      width: 180,
      type: 'input',
      hide: true,
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      searchLabelWidth: 90,
      span: 24
    },
    {
      label: '放款日期',
      prop: 'paymentDate',
      type: 'date',
      hide: true,
      searchRange: true,
      valueFormat: 'yyyy-MM-dd',
      search: true,
      searchSpan: 6,
      span: 12,
      width: 150
    },
    {
      label: '店铺名称',
      prop: 'storeName',
      width: 180,
      type: 'input',
      hide: true,
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      searchLabelWidth: 90,
      span: 24
    },
    // {
    //   label: '还款流水号',
    //   prop: 'enterpriseName',
    //   width: 180,
    //   type: 'input',
    //   hide: true,
    //   editDisplay: true,
    //   addDisplay: false,
    //   search: true,
    //   searchSpan: 6,
    //   searchLabelWidth: 90,
    //   span: 24,
    // },
    {
      label: '申请日期',
      prop: 'applyDate',
      type: 'date',
      hide: true,
      searchRange: true,
      valueFormat: 'yyyy-MM-dd',
      search: true,
      searchSpan: 6,
      span: 12,
      width: 150
    },
    // {
    //   label: '结清状态',
    //   prop: 'clearStatus',
    //   type: 'select',
    //   search: true,
    //   hide: true,
    //   dicData: [
    //     { label: '还款中', value: 'Repayment' },
    //     { label: '逾期未结清', value: 'Overdue' },
    //     { label: '逾期清分中', value: 'Clearing_Overdue' },
    //     { label: '申请结清中', value: 'Clearing_Apply' },
    //     { label: '正常结清', value: 'PayOff' },
    //     { label: '逾期结清', value: 'PayOff_Overdue' },
    //   ],
    // },
    {
      label: '申请日期',
      width: 200,
      prop: 'created',
      editDisplay: true,
      addDisplay: true,
      span: 12
    },
    {
      label: '融资单号',
      prop: 'applyNo',
      width: 200,
      editDisplay: false,
      addDisplay: false,
      span: 12
    },
    {
      label: '店铺名称/所属平台',
      prop: 'idLivePlatform',
      editDisplay: false,
      width: 200,
      addDisplay: false,
      span: 12
    },
    {
      label: '可申请金额',
      width: 200,
      prop: 'mayApplyAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12
    },
    {
      width: 200,
      label: '支用申请金额',
      prop: 'applyAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12
    },
    {
      label: '实际放款金额',
      width: 200,
      prop: 'paymentAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12
    },
    {
      label: '放款日期',
      prop: 'paymentDate',
      width: 200,
      editDisplay: false,
      addDisplay: false,
      span: 12
    },
    {
      label: '费率/计息方式',
      prop: 'frozenAmount',
      editDisplay: false,
      width: 200,
      addDisplay: false,
      span: 12
    },

    {
      label: '到期日',
      prop: 'expiredDate',
      editDisplay: false,
      addDisplay: false,
      span: 12,
      width: 200
    },
    {
      label: '申请/放款状态',
      prop: 'finProcStatusTips',
      editDisplay: false,
      width: 200,
      addDisplay: false,
      span: 12
    }
  ]
};

export default {
  name: 'TableUser',
  components: {
    channelAdd
  },
  data() {
    return {
      finProcStatusList: [5, 6, 9, 10],
      option: tableOption,
      searchItmes: {},
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10
      },
      isvisible: false,
      dataList: [],
      listLoading: true,
      accountId: '',
      accountName: '',
      billingMethod: {
        Charge_Per_transaction: '单笔',
        Annualized: '年化'
      },
      clearStatus: {
        Repayment: '还款中',
        Overdue: '逾期未结清',
        Clearing_Overdue: '逾期清分中',
        Clearing_Apply: '申请结清中',
        PayOff: '正常结清',
        PayOff_Overdue: '逾期结清'
      },
      livePlatform: {
        10: '抖音',
        11: '快手',
        12: '拼多多',
        13: '腾讯视频号',
        14: '美团',
        15: '抖音小时达'
      }
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    syncPermissions() {
      return this.permissions['biz_account_sync'];
    }
  },
  watch: {},
  created() {
    this.getNamelist();
  },
  methods: {
    isShow(status) {
      return this.finProcStatusList.find(item => item == status);
    },
    toPath(scope) {
      this.$router.push(`/finance/finance-order-detail?productType=${scope.row.productType}&id=${scope.row.storeQuotaId}&billingMethod=${this.billingMethod[scope.row.billingMethod]}&customerRate=${scope.row.customerRate}&applyAmount=${scope.row.paymentAmount}&paymentDate=${scope.row.paymentDate}`);
    },
    getNamelist() {
      const api = 'bium-portal/controller/fin-ops/account/getEnterpriseNameList';
      this.doPost(api).then(({ data }) => {
        if (data) {
          this.option.column[1].dicData = data || [];
        }
      });
    },
    handleSave() {
      this.isvisible = true;
    },
    invalid(id) {
      const api = 'bium-portal/controller/fin-ops/apply/invalid';
      this.doPost(api, {
        applyId: id
      }).then(({ data }) => {
        if (data.success) {
          this.resetFilter();
        }
      });
    },
    exportExcel() {
      const api = 'bium-portal/controller/fin-ops/bill/apply/page';
      const params = {
        ...this.searchItmes
      }
      if (params.accountNameDesc) {
        params.companyId = params.accountNameDesc
      }
      params.pageNum = 1
      params.pageSize = 99999
      params.paymentStartDate = params.paymentDate ? params.paymentDate[0] + ' 00:00:00' : '';
      params.paymentEndDate = params.paymentDate ? params.paymentDate[1] + ' 23:59:59' : '';
      params.applyStartDate = params.applyDate ? params.applyDate[0] + ' 00:00:00' : '';
      params.applyEndDate = params.applyDate ? params.applyDate[1] + ' 23:59:59' : '';
      delete params.settlementDate;
      this.doPost(api, params).then(({ data }) => {
        let records = data.result.records;
        records = records.map(item=>{
          item.idLivePlatform = item.storeName + '/' + this.livePlatform[item.livePlatform];
          item.frozenAmount = (Number(item.customerRate) * 100).toFixed(1) + '%/' + this.billingMethod[item.billingMethod];
          item.status = item.finProcStatus;
          if (item.finProcStatus == 7) {
            console.log('7');
            item.finProcStatus = item.zxcfinProcStatusDesc;
            console.log(item.finProcStatus);
          } else {
            item.finProcStatus =
              item.finProcStatus == 5
                ? '待放款'
                : item.finProcStatus == 6
                  ? '放款中 '
                  : item.finProcStatus == 9
                    ? '放款成功'
                    : item.finProcStatus == 10
                      ? '申请失败'
                      : '申请中';
          }
          return item;
        });
        const list = {
          title: ['申请日期', '融资单号', '店铺名称/所属平台', '可申请金额', '支用申请金额', '实际放款金额', '放款日期', '费率/计息方式', '到期日', '申请/放款状态'],
          key: ['created', 'applyNo', 'idLivePlatform', 'mayApplyAmount', 'applyAmount', 'paymentAmount', 'paymentDate', 'frozenAmount', 'expiredDate', 'finProcStatusDesc'],
          data: records, // 数据源
          autoWidth: true,
          filename: '融资申请单'
        }
        excel.exportArrayToExcel(list)
      })
    },
    getList(page, params) {
      const api = 'bium-portal/controller/fin-ops/bill/apply/page';
      const { currentPage, pageSize } = page;
      params = params || {
        ...this.searchItmes
      };
      if (params.accountNameDesc) {
        params.companyId = params.accountNameDesc;
      }
      params.pageNum = page.currentPage;
      params.pageSize = page.pageSize;
      params.paymentStartDate = params.paymentDate ? params.paymentDate[0] + ' 00:00:00' : '';
      params.paymentEndDate = params.paymentDate ? params.paymentDate[1] + ' 23:59:59' : '';
      params.applyStartDate = params.applyDate ? params.applyDate[0] + ' 00:00:00' : '';
      params.applyEndDate = params.applyDate ? params.applyDate[1] + ' 23:59:59' : '';
      this.listLoading = true;
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.page.total = data.result.total || 0;
          data.result.records.forEach((item) => {
            item.idLivePlatform = item.storeName + '/' + this.livePlatform[item.livePlatform];
            item.frozenAmount = (Number(item.customerRate) * 100).toFixed(1) + '%/' + this.billingMethod[item.billingMethod];
            item.status = item.finProcStatus;
            if (item.zxcfinProcStatusDesc) {
              item.finProcStatusTips = item.finProcStatusDesc + item.zxcfinProcStatusDesc;
            }
            else {
              item.finProcStatusTips = item.finProcStatusDesc;
            }
            if (item.finProcStatus == 7) {
              console.log('7');
              item.finProcStatus = item.zxcfinProcStatusDesc;
              console.log(item.finProcStatus);
            } else {
              item.finProcStatus =
                item.finProcStatus == 5
                  ? '待放款'
                  : item.finProcStatus == 6
                    ? '放款中 '
                    : item.finProcStatus == 9
                      ? '放款成功'
                      : item.finProcStatus == 10
                        ? '申请失败'
                        : '申请中';
            }
          });
          this.dataList = data.result.records || [];
        }
        this.listLoading = false;
      });
    },

    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param || {};
      this.getList(this.page, param);
      done();
    },
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },

    handleCloseDialog() {
      this.dialogVisible = false;
    },
    handleCellClassName({ row, column, rowIndex, columnIndex }) {
      if ([7].includes(columnIndex)) {
        return 'yellow-cell';
      }
      return '';
    },

    queryBook(row, index) {
      this.listLoading = true;
      doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    checkFlow() {
      doGet('/cap/account/checkFlow').then((res) => {
        this.handleRefreshChange();
      });
    },
    frozenBook(row, index) {
      this.listLoading = true;
      doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    handleWidthdrawal() {
      this.$router.push({
        path: '/account/withdraw'
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
