<template>
	<div class="product-funder-manage">
		<basic-container>
			<avue-crud ref="crud" :table-loading="isLoading" :page.sync="page" :option="option" :data="dataList"
				@on-load="getList" @size-change="sizeChange" @current-change="currentChange"
				@search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange"
				@row-update="handleRowUpdate">
				<template slot="menuRight">
					<el-button class="filter-item" type="primary" icon="el-icon-plus"
						@click="isvisible = true">添加资金方</el-button>
				</template>
				<template #intRate="{ row }">
					<span>{{ row.intRate }}%</span>
				</template>
				<template #channleRate="{ row }">
					<span>{{ row.channleRate }}%</span>
				</template>
				<template #remark="{ row }">
					<span>{{ row.remark }}</span>
				</template>
				<template slot-scope="{type,disabled,row}" slot="accountForm">
					<div class="corporate-box" v-if="dataForm.accountNameDesc">
						<div class="corporate-item">
							<div class="a_account c_title">
								<div>{{ dataForm.accountNameDesc }}</div>
								<div>{{ dataForm.accountNature == 1 ? '企业户' : '个人户' }}</div>
							</div>
							<div class="c_number fc_blue">{{ dataForm.walletId }}</div>
							<div class="c_account">
								<div class="c_account_bank">苏商银行</div>
								<div class="c_account_bank chand">
									<el-popover placement="right" width="400" trigger="click">
										<el-descriptions title="" :column="1">
											<!-- <el-descriptions-item label="账号">{{ dataForm.accountNo }}</el-descriptions-item> -->
											<el-descriptions-item label="所属银行">江苏苏商银行</el-descriptions-item>
											<el-descriptions-item label="开户支行">江苏苏商银行股份有限公司</el-descriptions-item>
											<el-descriptions-item label="开户支行号">323301000019</el-descriptions-item>
											<el-descriptions-item label="开户支行地址">江苏省南京市建邺区金融城4号楼</el-descriptions-item>
										</el-descriptions>
										<el-link slot="reference" type="primary">账户详情</el-link>
									</el-popover>
								</div>
							</div>

						</div>

					</div>
					<div class="corporate-box" v-else>
						<div class="corporate-item">
							<p class="corporate-item-p pt100">您的公司尚未绑定监管账户，请开通监管户</p>

						</div>

					</div>
				</template>
				<template slot="menu" slot-scope="scope">
					<el-button type="text" icon="el-icon-edit"
						@click="getbeforeOpen(scope.row, scope.index)">配置</el-button>
				</template>
			</avue-crud>
		</basic-container>
		<el-dialog
      title="新增资金方"
      class="dialog-standard channel-config-rate"
      width="1000px"
      top="100px"
			:visible.sync="isvisible"
    >
			<manageAdd @refreshTable="refreshTable" v-if="isvisible"></manageAdd>
		</el-dialog>
	</div>
</template>

<script>
import {
	PAGE
} from "@/const/global.js";
import {
	validNonnegativeNum
} from "@/utils/validate.js";
import manageAdd from './components-manage/manage-add'
export default {
	name: "ProductFunderManage",
	props: {},
	components: { manageAdd },
	data() {
		return {
			isvisible: false,
			isLoading: true,
			searchItmes: {},
			dataForm: {
			},
			dataList: [],
      isDisable: true,
			option: {
				searchMenuSpan: 12,
				searchMenuPosition: "left",
				border: false,
				index: false,
				indexLabel: "序号",
				stripe: true,
				addBtn: false,
				menu: true,
				editBtn: false,
				editBtnText: "配置",
				delBtn: false,
				menuWidth: "100",
				align: "center",
				editTitle: "资金方",
				dialogCustomClass: "form-dialog",
				searchShowBtn:false,
				column: [{
					label: "资金方名称",
					prop: "name",
					headerAlign: "left",
					align: "left",
					fixed: true,
					search: true,
					searchSpan: 5,
					searchLabelWidth: 100,
					// 弹窗表单配置
					labelWidth: 150,
					minWidth: 260,
					span: 22,
					editDisabled: false,
				},
				{
					label: "资金方所属企业",
					prop: "enterpriseName",
					headerAlign: "center",
					editDisplay: true,
					labelWidth: 150,
					minWidth: 260,
					editDisabled: true,
					span: 22,
					precision: 2,
					rules: [{
						required: true,
						message: "请输入",
						trigger: "blur"
					},

					],
				},
				{
					label: "资金成本（年化）%",
					prop: "costRateNumber",
					headerAlign: "center",
					width: 200,
					labelWidth: 150,
					span: 22,
					type: 'number',
					precision: 2,
					rules: [{
						required: true,
						message: "请输入",
						trigger: "blur"
					},

					],
				},
				{
					label: "通道费率（年化）%",
					prop: "routingRateNumber",
					headerAlign: "center",
					width: 200,
					labelWidth: 150,
					span: 22,
					type: 'number',
					precision: 2,
					rules: [{
						required: true,
						message: "请输入",
						trigger: "blur"
					}
					],
				},
				{
					label: "账户渠道",
					prop: "payName",
					headerAlign: "center",
					minWidth: 200,
					labelWidth: 150,
					span: 22,
					editDisplay: true,
					editDisabled: true,
				},
				{
					label: "产品类型",
					prop: "productType",
					headerAlign: "center",
					minWidth: 200,
					editDisplay: true,
					labelWidth: 150,
					span: 22,
					editDisabled: true,
				},
				{
					label: "资金方额度预审核",
          hide: true,
					prop: "toQuotaAudit",
					headerAlign: "center",
					minWidth: 200,
					editDisplay: true,
          editDisabled: this.isDisable,
					labelWidth: 150,
					span: 22,
          type: 'select',
          dicData: [
            { label: " 是", value: '2' },
            { label: "否", value: '0', }
          ]
				},
				{
					label: "资金方支用审核",
          hide: true,
					prop: "toAudit",
					headerAlign: "center",
					minWidth: 200,
					editDisplay: true,
          editDisabled: this.isDisable,
					labelWidth: 150,
					span: 22,
          type: 'select',
          dicData: [
            { label: " 是", value: '1' },
            { label: "否", value: '0', }
          ]
				},
				{
					label: "场景ID",
					prop: "accountScene",
					headerAlign: "center",
					minWidth: 200,
					labelWidth: 150,
					span: 22,
					editDisplay: true,
					editDisabled: true,
				},
				{
					label: "资金方状态",
					prop: "accountStatus",
					headerAlign: "center",
					editDisplay: true,
					minWidth: 200,
					labelWidth: 150,
					span: 22,
					editDisabled: true,
				},

				{
					label: "备注",
					prop: "remark",
					headerAlign: "center",
					minWidth: 200,
					labelWidth: 150,
					span: 22,
					editDisabled: false,
				},
				 {
					label: "资金方绑定账户",
					prop: "account",
					hide: true,
					minWidth: 200,
					labelWidth: 150,
					span: 22,
					editDisplay: true,
					editDisabled: true,
				},
				],
			},
			page: PAGE,
		};
	},
	watch: {},
	created() { },
	mounted() {
	},
	methods: {
		//编辑
		getbeforeOpen(row, index,) {
			this.dataForm = {}
			this.doPost("bium-portal/controller/fin-ops/account/selectAccount", {
				companyCode: row.enterpriseCode, //企业编号
				sceneId: row.accountScene //场景账户id
			}).then(({
				data
			}) => {
				if (data.errCode == 1000 && data.result) {
					this.dataForm = data.result[data.result.length - 1]
				}
        let json = {...row}
        if (row.productType == 1) {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
        json.toAudit = json.toAudit.toString();
        json.toQuotaAudit = json.toQuotaAudit.toString();
				this.$refs.crud.rowEdit(json, index)
			});

		},
		refreshTable() {
			this.getList(this.page);
		},
		/** 获取列表数据 */
		getList(page, params) {
			const api = "bium-portal/controller/fin-ops/fundingPartner/page";
			params = params ? params : {
				...this.searchItmes
			};

			// 封装接口参数
			params.pageNum = page.currentPage
			params.pageSize = page.pageSize
			// 调取接口
			this.isLoading = true;
			this.doPost(api, params).then(({
				data
			}) => {
				if (data.result.records) {
					this.dataList = data.result.records || []
					this.dataList.forEach(item => {
						item.costRateNumber = (Number(item.costRate) * 100).toFixed(2)
						item.routingRateNumber = (Number(item.routingRate) * 100).toFixed(2)
						item.productType = item.productType === 0 ? '1A-极速收款' : '2A-极速付款'
						item.accountStatus = item.accountStatus === 1 ? '可用' : '不可用'
						if (item.paymentType == 1) {
							item.payName = 'PING++'
						} else if (item.paymentType == 2) {
							item.payName = '富友支付'
						}
					})
					this.page.total = data.result.total || 0
				}
				this.isLoading = false;
			});
		},

		/** 分页变化，存储 */
		sizeChange(pageSize) {
			this.page.pageSize = pageSize;
		},
		/** 当前页码变化，存储 */
		currentChange(current) {
			this.page.currentPage = current;
		},
		/** 点击搜索栏搜索 */
		handleFilter(param, done) {
			this.page.currentPage = 1;
			this.searchItmes = param ? param : {};
			this.getList(this.page, param);
			done();
		},
		/** 重置条件搜索 */
		resetFilter() {
			this.page.currentPage = 1;
			this.searchItmes = {};
			this.getList(this.page, this.searchItmes);
		},
		/** 手动刷新列表 */
		handleRefreshChange() {
			this.getList(this.page, this.searchItmes);
		},

		/** 提交修改表达 */
		handleRowUpdate(row, index, done, loading) {
			const api = "bium-portal/controller/fin-ops/fundingPartner/update";
			const sendData = {
				id: row.id,
				name: row.name,
				costRate: row.costRateNumber,
				routingRate: row.routingRateNumber,
				remark: row.remark,
        toAudit: row.toAudit,
        toQuotaAudit: row.toQuotaAudit
			};
      if (row.productType == 1) {
        sendData.toAudit = 0;
        sendData.toQuotaAudit = 0;
      }
			sendData.costRate = (Number(sendData.costRate) / 100).toFixed(4)
			sendData.routingRate = (Number(sendData.routingRate) / 100).toFixed(4)
			this.doPost(api, sendData)
				.then(({
					data
				}) => {
					if (data) {
						this.$notify.success("修改成功。");
						this.page.currentPage = 1;
						this.handleRefreshChange();
						done();
					}
					loading();
				})
				.catch(() => {
					loading();
				});
		},

	},
};
</script>

<style lang="scss" scoped>
.pt100{
	padding-top: 40px !important;
}</style>
