<template>
  <div>
    <div>
      <el-divider content-position="left" class="title-divider">文件信息
      </el-divider>
      <div class="jg-acc">
        <div class="card-box flexBox">
          <div
            class="file-item flexBox-item"
            v-for="(item, index) in fileList"
            :key="index"
          >
            <img
              :src="fileObj[item.value]"
              class="flexBox-item-img"
            />
            <div class="maskView">
              <div class="maskView_action" @click="showFile(item)">
                <i class="el-icon-picture-outline"></i>
                查看大图
              </div>
              <div class="maskView_action">
                <el-upload
                  action="http-request"
                  :name="item.value"
                  ref="upload"
                  :limit="1"
                  :file-list="files"
                  :show-file-list="false"
                  list-type="text"
                  :http-request="httpRequest"
                >
                  <div class="maskView_action">
                    <i class="el-icon-upload2"></i>
                    重新上传
                  </div>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="查看图片"
      class="dialog-standard channel-config-rate"
      width="600px"
      top="100px"
      :visible.sync="isvisible"
    >
      <div class="flex-c">
        <img style="width: 500px;height: 400px;" :src="fileUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { doImg, doPostMulti } from '@/router/axios'
export default {
  name: "ChannelConfigRate",
  props: {
    code: {
      type: String | Number,
      default: false,
    },
    fileArr: {
      type: Array,
      default: false,
    },
  },
  data() {
    return {
      isvisible: false,
      fileUrl: '',
      fileList: [],
      files: [],
      fileObj: {},
    }
  },
  created() {
    let flie = [{
      title: '营业执照照片',
      value: 'QYYYZZ'
    }, {
      title: '法人身份证正面',
      value: 'FRSFZZM'
    }, {
      title: '法人身份证反面',
      value: 'FRSFZFM'
    }, {
      title: '法人手持身份证照片',
      value: 'FRSCSFZ'
    },{
      title: '授权人身份证正面',
      value: 'SQRSFZZM'
    }, {
      title: '授权人身份证反面',
      value: 'SQRSFZFM'
    },]
    this.fileList = []
    this.fileArr.forEach((item) => {
      flie.forEach((itn) => {
        if(itn.value == item.typeCode || itn.value == item.attachmentType){
          this.fileList.push(itn)
          this.fileObj[item.typeCode] = '';
          this.openFile(itn);
        }
      });
    });
  },
  methods: {
    openFile(item) {
      const form = {
        enterpriseId: this.code,
        typeCode: item.value
      }
      doImg("/bium-portal/controller/fin-ops/attach/viewByCode", form).then(({
        data
      }) => {
        if (data) {
          this.$nextTick(function(){
            this.fileObj[item.value] = window.URL.createObjectURL(data);
            console.log('res', window.URL.createObjectURL(data));
          });
        }
      });
    },
    showFile(item) {
      const form = {
        enterpriseId: this.code,
        typeCode: item.value
      }
      doImg("/bium-portal/controller/fin-ops/attach/viewByCode", form).then(({
        data
      }) => {
        if (data) {
          this.fileUrl = window.URL.createObjectURL(data)
          this.isvisible = true;
        }
      });
    },
    httpRequest(file) {
      const params = new FormData();
      params.append('enterpriseId', this.code);
      params.append('file', file.file);
      params.append('typeCode', file.filename);
      doPostMulti('/bium-portal/controller/fin-ops/attach/modify', params)
        .then(res=>{
          console.log('res', res);
          this.$emit('onChange')
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.flexBox {
  display: flex;
  flex-wrap: wrap;
}
.flexBox-item {
  width: 32% !important;
  padding: 0 !important;
  margin-bottom: 15px;
  margin: 0 0 15px 0 !important;
  position: relative;
}
.flexBox-item:nth-child(3n-1) {
  margin: 0 2% 15px 2% !important;
}
.flexBox-item-img {
  height: 180px;
  width: 100% !important;
}
.flexBox-item:hover .maskView {
  opacity: 1;
}
.maskView {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  opacity: 0;
}
.maskView_action {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #ffffff;
  cursor: pointer;
}
</style>
