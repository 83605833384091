var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selection-box" }, [
    _c(
      "section",
      { staticClass: "acc-container" },
      [
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("基本信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("客户名称：")]),
              _c("div", [_vm._v(_vm._s(_vm.form.enterpriseName))]),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("企业编号：")]),
              _c("div", [_vm._v(_vm._s(_vm.form.enterpriseCode))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("联系方式：")]),
              _c("div", [_vm._v(_vm._s(_vm.form.mobile))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("所属渠道：")]),
              _vm.form.channelId
                ? _c("div", [_vm._v(_vm._s(_vm.form.channelName))])
                : _c("div", [_vm._v("直客")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("企业状态：")]),
              _vm.form.enterpriseStatus == 1
                ? _c("div", [_vm._v("认证中")])
                : _vm._e(),
              _vm.form.enterpriseStatus == 2
                ? _c("div", [_vm._v("认证成功")])
                : _vm._e(),
              _vm.form.enterpriseStatus == 3
                ? _c("div", [_vm._v("认证失败")])
                : _vm._e(),
              _vm.form.enterpriseStatus == 4
                ? _c("div", [_vm._v("待审核")])
                : _vm._e(),
              _vm.form.enterpriseStatus == 5
                ? _c("div", [_vm._v("审核通过")])
                : _vm._e(),
              _vm.form.enterpriseStatus == 6
                ? _c("div", [_vm._v("审核拒绝")])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("所剩额度：")]),
              _c("div", [_vm._v(_vm._s(_vm.form.residueAmount))]),
            ]),
            _c(
              "div",
              { staticClass: "card-item" },
              [
                _c("el-input", {
                  staticStyle: { width: "184px" },
                  attrs: { type: "number", disabled: _vm.residueAmountType },
                  model: {
                    value: _vm.form.creditAmount,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "creditAmount", $$v)
                    },
                    expression: "form.creditAmount",
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "1px 0 0 8px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.editResidueAmoun },
                  },
                  [
                    !_vm.residueAmountType
                      ? _c("span", [_vm._v("确认保存")])
                      : _vm._e(),
                    _vm.residueAmountType
                      ? _c("span", [_vm._v("修改授信总额度")])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm.shopList.length
          ? _c(
              "el-divider",
              {
                staticClass: "title-divider",
                attrs: { "content-position": "left" },
              },
              [_vm._v("店铺信息")]
            )
          : _vm._e(),
        _vm.shopList.length
          ? _c(
              "div",
              { staticClass: "jg-acc" },
              _vm._l(_vm.shopList, function (u, index) {
                return _c("div", { key: index, staticClass: "card-box" }, [
                  _c("div", { staticClass: "card-item" }, [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺名称:"),
                    ]),
                    _c("div", [_vm._v(_vm._s(u.name))]),
                  ]),
                  _c("div", { staticClass: "card-item" }, [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺所属平台:"),
                    ]),
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺所属平台:"),
                    ]),
                    u.livePlatform == 10
                      ? _c("div", [_vm._v("抖音")])
                      : _vm._e(),
                    u.livePlatform == 11
                      ? _c("div", [_vm._v("快手")])
                      : _vm._e(),
                    u.livePlatform == 12
                      ? _c("div", [_vm._v("拼多多")])
                      : _vm._e(),
                    u.livePlatform == 13
                      ? _c("div", [_vm._v("腾讯视频号")])
                      : _vm._e(),
                    u.livePlatform == 14
                      ? _c("div", [_vm._v("美团")])
                      : _vm._e(),
                    u.livePlatform == 15
                      ? _c("div", [_vm._v("抖音小时达")])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "card-item" }, [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺ID:"),
                    ]),
                    _c("div", [_vm._v(_vm._s(u.storeCode))]),
                  ]),
                  _c("div", { staticClass: "card-item" }, [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺账户ID:"),
                    ]),
                    _c("div", [_vm._v(_vm._s(u.storekeeperAcctId))]),
                  ]),
                  _c("div", { staticClass: "card-item" }, [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺账号状态:"),
                    ]),
                    u.storeStatus == "controlled"
                      ? _c("div", [_vm._v("已管控")])
                      : _vm._e(),
                    u.storeStatus == "un_controlled"
                      ? _c("div", [_vm._v("未管控")])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "card-item" }, [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺账户状态:"),
                    ]),
                    u.virtualAcctStatus == "controlled"
                      ? _c("div", [_vm._v("已管控")])
                      : _vm._e(),
                    u.virtualAcctStatus == "un_controlled"
                      ? _c("div", [_vm._v("未管控")])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "card-item" }, [
                    _c("div", { staticClass: "item_label" }, [
                      _vm._v("店铺管控手机号:"),
                    ]),
                    _c("div", [_vm._v(_vm._s(u.bindingMobile))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-item" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openPath("/creditReport/index", {
                                id: u.id,
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                      查看风控报告\n                  "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("监管账户信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("账户号:")]),
              _vm.accountFrom.accountNo
                ? _c("div", [_vm._v(_vm._s(_vm.accountFrom.accountNo))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("所属银行:")]),
              _vm.accountFrom.bankName
                ? _c("div", [_vm._v(_vm._s(_vm.accountFrom.bankName))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("开户支行号:")]),
              _vm.accountFrom.bankBranchNo
                ? _c("div", [_vm._v(_vm._s(_vm.accountFrom.bankBranchNo))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("账户系统:")]),
              _vm.accountFrom.paymentChannel == 1
                ? _c("div", [_vm._v("Ping++")])
                : _vm.accountFrom.paymentChannel == 2
                ? _c("div", [_vm._v("富友支付")])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("开户行地址:")]),
              _vm.accountFrom.bankBranchAddress
                ? _c("div", [_vm._v(_vm._s(_vm.accountFrom.bankBranchAddress))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("账户余额:")]),
              _c("div", [_vm._v(_vm._s(_vm.accountFrom.totalBalance))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("可用额度:")]),
              _c("div", [_vm._v(_vm._s(_vm.accountFrom.availableAmount))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("冻结额度:")]),
              _c("div", [_vm._v(_vm._s(_vm.accountFrom.frozenAmount))]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("费率信息")]
        ),
        _vm.form.productType == 1
          ? _c("div", { staticClass: "jg-acc" }, [
              _c("div", { staticClass: "card-box" }, [
                _c("div", { staticClass: "card-item" }, [
                  _c("div", { staticClass: "item_label" }, [_vm._v("资金方:")]),
                  _c("div", [_vm._v(_vm._s(_vm.form.fundingName))]),
                ]),
                _c("div", { staticClass: "card-item" }, [
                  _c("div", { staticClass: "item_label" }, [
                    _vm._v("每日还款比例:"),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.form.dailyRepaymentRatio))]),
                ]),
                _c("div", { staticClass: "card-item" }, [
                  _c("div", { staticClass: "item_label" }, [
                    _vm._v("对客费率（年化）:"),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.form.customerRate1) + "%")]),
                ]),
                _c("div", { staticClass: "card-item" }, [
                  _c("div", { staticClass: "item_label" }, [
                    _vm._v("用款周期:"),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.form.paymentDays) + "天")]),
                ]),
                _c("div", { staticClass: "card-item" }, [
                  _c("div", { staticClass: "item_label" }, [
                    _vm._v("渠道归属:"),
                  ]),
                  _vm.form.channelId
                    ? _c("div", [_vm._v(_vm._s(_vm.form.channelName))])
                    : _vm._e(),
                  _vm.form.channelId
                    ? _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.form.channelLevel == 1
                                ? "一级渠道"
                                : _vm.form.channelLevel == 2
                                ? "二级渠道"
                                : "三级渠道"
                            ) +
                            "\n          "
                        ),
                      ])
                    : _c("div", [_vm._v("直客")]),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("企业营业信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("企业名称:")]),
              _c("div", [_vm._v(_vm._s(_vm.form.enterpriseName))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("社会信用代码:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.creditCode))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业地址信息:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.address))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("营业执照有效期:"),
              ]),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.form.establishmentDate) +
                    "-" +
                    _vm._s(_vm.form.validDate)
                ),
              ]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("法人信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("法人姓名:")]),
              _vm.form.legalName
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalName))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("法人性别:")]),
              _vm.form.legalGender == 1 ? _c("div", [_vm._v("男")]) : _vm._e(),
              _vm.form.legalGender == 2 ? _c("div", [_vm._v("女")]) : _vm._e(),
              !_vm.form.legalGender ? _c("div", [_vm._v("--")]) : _vm._e(),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("法人身份证号:"),
              ]),
              _vm.form.legalCard
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalCard))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("身份证有效期:"),
              ]),
              _vm.form.legalDuration
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalDuration))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("法人邮箱:")]),
              _vm.form.legalMail
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalMail))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("法人电话:")]),
              _vm.form.legalMobile
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalMobile))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("法人姓名:")]),
              _vm.form.legalName
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalName))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("身份证住址:")]),
              _vm.form.legalAddress
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalAddress))])
                : _c("div", [_vm._v("--")]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("授权人信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("授权人姓名:")]),
              _vm.form.authorizationName
                ? _c("div", [_vm._v(_vm._s(_vm.form.authorizationName))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("授权人性别:")]),
              _vm.form.authorizationGender == 1
                ? _c("div", [_vm._v("男")])
                : _vm._e(),
              _vm.form.authorizationGender == 2
                ? _c("div", [_vm._v("女")])
                : _vm._e(),
              !_vm.form.authorizationGender
                ? _c("div", [_vm._v("--")])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("授权人身份证号:"),
              ]),
              _vm.form.authorizationCard
                ? _c("div", [_vm._v(_vm._s(_vm.form.authorizationCard))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("身份证有效期:"),
              ]),
              _vm.form.authorizationDuration
                ? _c("div", [_vm._v(_vm._s(_vm.form.authorizationDuration))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("授权人邮箱:")]),
              _vm.form.authorizationMail
                ? _c("div", [_vm._v(_vm._s(_vm.form.authorizationMail))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("授权人电话:")]),
              _vm.form.authorizationMobile
                ? _c("div", [_vm._v(_vm._s(_vm.form.authorizationMobile))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("身份证住址:")]),
              _vm.form.authorizationAddress
                ? _c("div", [_vm._v(_vm._s(_vm.form.authorizationAddress))])
                : _c("div", [_vm._v("--")]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "card-item" }, [
      _c("div", { staticClass: "item_label" }, [_vm._v("产品号:")]),
      _c("div", [_vm._v("2A-极速付款")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }