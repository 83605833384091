var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selection-box" }, [
    _c(
      "section",
      { staticClass: "acc-container" },
      [
        _vm._l(_vm.pageDetail, function (item) {
          return [
            _c("div", { key: item.id }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("div", { staticClass: "title-divider" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  item.canEdit
                    ? _c("div", [
                        _vm.editType == item.key
                          ? _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSave()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                保存\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onCancel()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                取消\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("i", {
                              staticClass: "el-icon-edit-outline",
                              on: {
                                click: function ($event) {
                                  return _vm.onEdit(item.key)
                                },
                              },
                            }),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "jg-acc" }, [
                _c(
                  "div",
                  { staticClass: "card-box" },
                  _vm._l(item.data, function (json) {
                    return _c(
                      "div",
                      { key: json.value, staticClass: "card-item" },
                      [
                        _c("div", { staticClass: "item_label" }, [
                          _vm._v(_vm._s(json.label) + "："),
                        ]),
                        _vm.editType == item.key && json.canEdit
                          ? _c(
                              "div",
                              [
                                json.type == "input"
                                  ? _c("el-input", {
                                      model: {
                                        value: _vm.form[item.key][json.value],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form[item.key],
                                            json.value,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form[item.key][json.value]",
                                      },
                                    })
                                  : _vm._e(),
                                json.type == "date"
                                  ? _c("el-date-picker", {
                                      attrs: {
                                        type: "date",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "选择日期",
                                      },
                                      model: {
                                        value: _vm.form[item.key][json.value],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form[item.key],
                                            json.value,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form[item.key][json.value]",
                                      },
                                    })
                                  : _vm._e(),
                                json.type == "select"
                                  ? _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "请选择店铺所属平台",
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.form[item.key].livePlatform,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form[item.key],
                                              "livePlatform",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form[item.key].livePlatform",
                                        },
                                      },
                                      _vm._l(
                                        _vm.livePlatformList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                _vm._s(_vm.detailData[item.key][json.value])
                              ),
                            ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }