var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fullWidth" },
    [
      _c("p", { staticStyle: { margin: "10px 0" } }, [
        _vm._v(_vm._s(_vm.fileType[_vm.file.typeCode])),
      ]),
      _c("img", {
        staticClass: "flexBox-item-img",
        attrs: { src: _vm.fileUrl },
      }),
      _c("div", { staticClass: "maskView" }, [
        _c(
          "div",
          {
            staticClass: "maskView_action",
            on: {
              click: function ($event) {
                return _vm.showFile()
              },
            },
          },
          [
            _c("i", { staticClass: "el-icon-picture-outline" }),
            _vm._v("\n      查看大图\n    "),
          ]
        ),
        _c(
          "div",
          { staticClass: "maskView_action" },
          [
            _c(
              "el-upload",
              {
                ref: "upload",
                attrs: {
                  action: "http-request",
                  name: _vm.file.typeCode,
                  limit: 1,
                  "file-list": _vm.files,
                  "show-file-list": false,
                  "list-type": "text",
                  "http-request": _vm.httpRequest,
                },
              },
              [
                _c("div", { staticClass: "maskView_action" }, [
                  _c("i", { staticClass: "el-icon-upload2" }),
                  _vm._v("\n          重新上传\n        "),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "查看图片",
            width: "600px",
            top: "100px",
            visible: _vm.isvisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isvisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex-c" }, [
            _c("img", {
              staticStyle: { width: "500px", height: "400px" },
              attrs: { src: _vm.fileUrl, alt: "" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }