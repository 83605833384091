<template>
  <div>
    <div v-if="fileArr.length > 0">
      <el-divider content-position="left" class="title-divider">协议文本
      </el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="file-item" v-for="(item, index) in fileArr" :key="index">
            <div class="file-item-line">
              <div>
                <div class="fc_333 fb">
                  {{ item.filename }}
                  <label>{{ flowStatusToText[item.flowStatus] }}/{{ item.signStart }}</label>
                </div>
              </div>
              <div v-if="item.flowStatus == 9">
                <div class="fc_blue fc_333 fb chand" @click="openFile(item)">查看</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <el-dialog title="查看图片" class="dialog-standard channel-config-rate" width="600px" top="100px"
      :visible.sync="isvisible">
      <div class="flex-c">
        <img style="width: 500px;height: 400px;" :src="fileUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { doExportExcel } from '../../../router/axios'
import { renderAsync } from "docx-preview";
export default {
  name: "ChannelConfigRate",
  props: {
    code: {
      type: String,
      default: false,
    },
    fileArr: {
      type: Array,
      default: false,
    },
  },
  data() {
    return {
      isvisible: false,
      fileUrl: '',
      fileList: [],
      flowStatusToText: {
        1: '签署中',
        2: '已完成',
        3: '已撤销',
        5: '已过期',
        7: '已拒签',
        9: '已下载'
      },
    }
  },
  created() {
    console.log('fileArr'.this.fileArr);
    let flie = [{
      title: '《信息授权函》',
      value: 'company_data_auth',
      path: '支用/中诚信用户/授权书.pdf',
      type: 1
    }, {
      title: '《服务与认证协议》',
      value: 'serv_auth_confirm',
      path: '企业注册认证/供应链金融平台服务协议.docx',
      type: 2
    }, {
      title: '《保理合同》',
      value: 'factoring_contract',
      path: '企业监管户开户/普通保理用户/保理合同.docx',
      type: 2
    }, {
      title: '《资金监管协议》',
      value: 'fund_supervision_agreement',
      path: '企业监管户开户/普通保理用户/账户资金监管服务协议.docx',
      type: 2
    },]
    this.fileList = []
    this.fileArr.forEach((itn) => {
      if (itn.fileSuffix == '.pdf' || itn.fileSuffix == '.docx') {
        this.fileList.push(itn)
      }
    })
  },
  methods: {
    openFile(item) {
      const form = {
        id: item.id,
      }
      doExportExcel(`bium-portal/controller/fin-ops/esign/view/${item.id}`)
        .then(res=>{
          const blob = new Blob([res.data], { type: "application/pdf" });
          window.open(window.URL.createObjectURL(blob), "_blank");
        })
    }
  },
};
</script>

<style lang="scss" scoped></style>
