<template>
  <div
    class="fullWidth"
  >
    <p style="margin: 10px 0;">{{fileType[file.typeCode]}}</p>
    <img
      :src="fileUrl"
      class="flexBox-item-img"
    />
    <div class="maskView">
      <div class="maskView_action" @click="showFile()">
        <i class="el-icon-picture-outline"></i>
        查看大图
      </div>
      <div class="maskView_action">
        <el-upload
          action="http-request"
          :name="file.typeCode"
          ref="upload"
          :limit="1"
          :file-list="files"
          :show-file-list="false"
          list-type="text"
          :http-request="httpRequest"
        >
          <div class="maskView_action">
            <i class="el-icon-upload2"></i>
            重新上传
          </div>
        </el-upload>
      </div>
    </div>
    <el-dialog
      title="查看图片"
      class="dialog-standard channel-config-rate"
      width="600px"
      top="100px"
      :visible.sync="isvisible"
    >
      <div class="flex-c">
        <img style="width: 500px;height: 400px;" :src="fileUrl" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { doImg, doPostMulti } from '@/router/axios'
export default {
  name: "ChannelConfigRate",
  props: {
    code: {
      type: String | Number,
      default: false,
    },
    file: {
      type: Object,
      default: false,
    },
  },
  data() {
    return {
      isvisible: false,
      fileUrl: '',
      files: [],
      fileType: {
        'QYYYZZ': '营业执照照片',
        'FRSFZZM': '法人身份证正面',
        'FRSFZFM': '法人身份证反面',
        'FRSCSFZ': '法人手持身份证照片',
        'SQRSFZZM': '授权人身份证正面',
        'SQRSFZFM': '授权人身份证反面',
      }
    }
  },
  created() {
    console.log('')
    this.openFile()
  },
  methods: {
    openFile() {
      const form = {
        enterpriseId: this.code,
        typeCode: this.file.typeCode
      }
      doImg("/bium-portal/controller/fin-ops/attach/viewByCode", form).then(({
        data
      }) => {
        if (data) {
          console.log('window.URL.createObjectURL(data)', window.URL.createObjectURL(data))
          this.fileUrl = window.URL.createObjectURL(data);
        }
      });
    },
    showFile() {
      this.isvisible = true;
    },
    httpRequest(file) {
      const params = new FormData();
      params.append('enterpriseId', this.code);
      params.append('file', file.file);
      params.append('typeCode', file.filename);
      doPostMulti('/bium-portal/controller/fin-ops/attach/modify', params)
        .then(res=>{
          console.log('res', res);
          this.$emit('onChange')
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.flexBox {
  display: flex;
  flex-wrap: wrap;
}
.flexBox-item {
  width: 32% !important;
  padding: 0 !important;
  margin-bottom: 15px;
  margin: 0 0 15px 0 !important;
  position: relative;
}
.flexBox-item:nth-child(3n-1) {
  margin: 0 2% 15px 2% !important;
}
.flexBox-item-img {
  height: 180px;
  width: 100% !important;
}
.flexBox-item:hover .maskView {
  opacity: 1;
}
.fullWidth {
  width: 100%;
}
.maskView {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  opacity: 0;
}
.maskView_action {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #ffffff;
  cursor: pointer;
}
</style>
