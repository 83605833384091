<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :before-open="handleOpenBefore"
        :data="list"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >
        <template #menuLeft>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="item in tabList" :key="item.value" :label="item.label" :name="item.value" />
          </el-tabs>
        </template>
        <template slot="type" slot-scope="scope">
          <span v-if="scope.row.type == 0">企业审核</span>
          <span v-if="scope.row.type == 1">店铺审核</span>
          <span v-if="scope.row.type == 2">支用审核</span>
          <span v-if="scope.row.type == 3">提现审核</span>
        </template>
        <template slot="status" slot-scope="scope">
          <span v-if="scope.row.status == 0">待审核</span>
          <span v-if="scope.row.status == 1">审核通过</span>
          <span v-if="scope.row.status == 2">审核失败</span>
        </template>
        <template slot="currNode" slot-scope="scope">
          <span v-if="scope.row.currNode == 'ops'">运营</span>
          <span v-if="scope.row.currNode == 'risk'">风控</span>
          <span v-if="scope.row.currNode == 'financial'">财务</span>
        </template>
        <template slot="productType" slot-scope="scope">
          <span v-if="scope.row.productType == 0">1A-极速收款</span>
          <span v-if="scope.row.productType == 1">2A-极速付款</span>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="handleApproval(scope.row)">详情</el-button>
          <el-button v-if="scope.row.status == 0" type="text" @click="openDIalog('examinedialog', scope.row)">
            审批
          </el-button>
          <el-button v-if="activeName == '0'" type="text" @click="openDIalog('Customerdialog', scope.row)">
            客户绑定
          </el-button>
          <!--          <el-button v-if="scope.row.isRatePlan" type="text" @click="openDIalog('Ratesvisible', scope.row)">费率方案-->
          <!--          </el-button>-->
          <el-button type="text" @click="openDIalog('Caseisvisible', scope.row)">新增案例</el-button>
          <el-button type="text" @click="openDIalog('casedialog', scope.row)">案例记录</el-button>
          <!--          <el-button v-if="scope.row.isCreditInfo" type="text" @click="openDIalog('creditvisible', scope.row)">-->
          <!--            征信信息-->
          <!--          </el-button>-->
        </template>
      </avue-crud>
    </basic-container>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="Caseisvisible"
      class="dialog-standard channel-config-rate"
      title="新增案例"
      top="100px"
      width="550px"
    >
      <addCase v-if="Caseisvisible" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="casedialog"
      class="dialog-standard channel-config-rate"
      title="案例记录"
      top="100px"
      width="800px"
    >
      <CaseRecord v-if="casedialog" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="examinedialog"
      class="dialog-standard channel-config-rate"
      title="审核操作"
      top="100px"
      width="800px"
    >
      <examine v-if="examinedialog" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="Customerdialog"
      class="dialog-standard channel-config-rate"
      title="客户归属绑定"
      top="100px"
      width="700px"
    >
      <customer v-if="Customerdialog" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="Ratesvisible"
      class="dialog-standard channel-config-rate"
      title="费率方案"
      top="100px"
      width="600px"
    >
      <RatePlan v-if="Ratesvisible" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="creditvisible"
      class="dialog-standard channel-config-rate"
      title="征信报告"
      top="100px"
      width="1000px"
    >
      <creditReport v-if="creditvisible" :row-form="rowItem" />
    </el-dialog>
    <!-- <el-drawer
      title="店铺授信审核详情"
      :visible.sync="visable"
      direction="rtl"
      size="800px"
    >
      <div v-loading="loaded" class="selection-box">
        <section class="acc-container">
          <el-divider class="title-divider" content-position="left">基本信息</el-divider>
          <div class="jg-acc">
            <div class="card-box">
              <div v-for="(item, index) in customerInfo" :key="index" class="card-item">
                <div class="item_label">{{ item.name }}：</div>
                <div>
                  <span v-if="item.name == '审核类型'">
                    <span v-if="dataDetails[item.value] == 0">企业审核</span>
                    <span v-if="dataDetails[item.value] == 1">店铺审核</span>
                    <span v-if="dataDetails[item.value] == 2">支用审核</span>
                    <span v-if="dataDetails[item.value] == 3">提现审核</span>
                  </span>
                  <span v-else-if="item.name == '状态'">
                    <span v-if="dataDetails[item.value] == 0">待审核</span>
                    <span v-if="dataDetails[item.value] == 1">审核通过</span>
                    <span v-if="dataDetails[item.value] == 2">审核失败</span>
                  </span>
                  <span v-else-if="item.value == 'productType'">
                    <span v-if="dataDetails[item.value] == 0">1A-极速收款</span>
                    <span v-if="dataDetails[item.value] == 1">2A-极速付款</span>
                  </span>
                  <span v-else>{{ dataDetails[item.value] }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.content.auditWithdrawEntity">
            <el-divider class="title-divider" content-position="left">提现信息</el-divider>

            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in Withdraw_info" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>

                  <div>
                    <span>
                      {{
                        dataDetails.content.auditWithdrawEntity[item.value] ||
                          dataDetails.content.auditWithdrawEntity[item.value] == 0
                          ? dataDetails.content.auditWithdrawEntity[item.value]
                          : '--'
                      }}
                      <span
                        v-if="
                          dataDetails.content.auditWithdrawEntity[item.value] ||
                            dataDetails.content.auditWithdrawEntity[item.value] == 0
                        "
                      >
                        {{ item.unit }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.content.auditApplyOrderEntity">
            <el-divider class="title-divider" content-position="left">订单信息</el-divider>
            <div class="mb10">
              <el-button
                type="primary"
                @click="goPath('/wfTaskInfo/orderDetails', { orderNo: dataDetails.content.auditApplyOrderEntity.orderNo })"
              >
                查看订单详情
              </el-button>
            </div>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in order_info" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>

                  <div>
                    <span v-if="item.value == 'creditRatio'">
                      {{
                        dataDetails.content.auditApplyOrderEntity[item.value]
                          ? dataDetails.content.auditApplyOrderEntity[item.value]
                          : '--'
                      }}
                      <span v-if="dataDetails.content.auditApplyOrderEntity[item.value]">%</span>
                    </span>
                    <span v-else-if="item.value == 'orderPaidRatio'">
                      {{ (Number(dataDetails.content.auditApplyOrderEntity[item.value]) * 100).toFixed(0) }}%
                    </span>
                    <span v-else>
                      {{
                        dataDetails.content.auditApplyOrderEntity[item.value]
                          ? dataDetails.content.auditApplyOrderEntity[item.value]
                          : '--'
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.content.auditCreditAmountEntity">
            <el-divider class="title-divider" content-position="left">额度信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in AuditCreditAmountEntity" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>

                  <div>
                    <span v-if="dataDetails.content.auditCreditAmountEntity[item.value]">
                      {{ dataDetails.content.auditCreditAmountEntity[item.value] }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.content.auditStoreEntity">
            <el-divider class="title-divider" content-position="left">店铺信息</el-divider>

            <div class="mb10">
              <el-button
                type="primary"
                @click="
                  openPath('/creditReport/index', {
                    id: dataDetails.content.auditStoreEntity.id,
                    livePlatform: dataDetails.content.auditStoreEntity.livePlatform,
                    storeCode: dataDetails.content.auditStoreEntity.storeCode,
                    storename: dataDetails.content.auditStoreEntity.name,
                    entName: dataDetails.content.auditEnterpriseEntity.name,
                  })
                "
              >
                查看风控报告
              </el-button>
            </div>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in shopformation" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>

                  <div>
                    <span v-if="item.value == 'livePlatform'">
                      <span v-if="dataDetails.content.auditStoreEntity[item.value] == 10">抖音</span>
                      <span v-if="dataDetails.content.auditStoreEntity[item.value] == 11">快手</span>
                      <span v-if="dataDetails.content.auditStoreEntity[item.value] == 12">拼多多</span>
                      <span v-if="dataDetails.content.auditStoreEntity[item.value] == 13">腾讯视频号</span>
                      <span v-if="dataDetails.content.auditStoreEntity[item.value] == 14">美团</span>
                    </span>
                    <span v-else-if="item.value == 'storeStatusDesc'">
                      {{ storeStatusDesc }}
                    </span>
                    <span v-else-if="item.value == 'virtualAcctStatusDesc'">
                      {{ virtualAcctStatusDesc }}
                    </span>
                    <span v-else>{{ dataDetails.content.auditStoreEntity[item.value] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.productType == 0 && dataDetails.content.auditRatePlanEntity">
            <el-divider class="title-divider" content-position="left">费率信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in direct" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>
                  <div>
                    <span v-if="item.value == 'firstTraceDays' || item.value == 'multipleTraceDays'">
                      T - {{ dataDetails.content.auditRatePlanEntity[item.value] }}天
                    </span>
                    <span v-else-if="item.value == 'channelName'">
                      {{
                        dataDetails.content.auditRatePlanEntity.channelName
                          ? dataDetails.content.auditRatePlanEntity.channelName
                          : '直客'
                      }}
                    </span>
                    <el-button v-else-if="item.value == 'btn'" type="primary" @click="toConfigStandardPlan(dataDetails)">
                      查看费率
                    </el-button>
                    <span v-else>{{ dataDetails.content.auditRatePlanEntity[item.value] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.productType == 1 && dataDetails.content.auditRatePlanEntity">
            <el-divider class="title-divider" content-position="left">费率信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in directsType" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>
                  <div>
                    <span v-if="item.value == 'paymentDays'">
                      {{ dataDetails.content.auditRatePlanEntity[item.value] }}天
                    </span>
                    <span v-else-if="item.value == 'customerRate'">
                      {{
                        (Number(dataDetails.content.auditRatePlanEntity.custRateDetailList[0].customerRate) * 100).toFixed(
                          4,
                        )
                      }}%
                    </span>
                    <span v-else-if="item.value == 'channelName'">
                      {{
                        dataDetails.content.auditRatePlanEntity.channelName
                          ? dataDetails.content.auditRatePlanEntity.channelName
                          : '直客'
                      }}
                    </span>
                    <span v-else>{{ dataDetails.content.auditRatePlanEntity[item.value] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.content.auditApplyEntity">
            <el-divider class="title-divider" content-position="left">支用信息</el-divider>

            <div class="mb10">
              <el-button
                type="primary"
                @click="
                  goPath('/wfTaskInfo/disbursement', {
                    id: dataDetails.content.auditApplyEntity.storeQuotaId,
                  })
                "
              >
                查看订单详情
              </el-button>
            </div>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in Disbursementformation" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>
                  <div>
                    <span
                      v-if="
                        item.value == 'riskDiscountRate' ||
                          item.value == 'realityDiscountRate' ||
                          item.value == 'customerRate'
                      "
                    >
                      {{ (Number(dataDetails.content.auditApplyEntity[item.value]) * 100).toFixed(4) }}{{ item.unit }}
                    </span>
                    <span v-else-if="dataDetails.content.auditApplyEntity[item.value]">
                      {{ dataDetails.content.auditApplyEntity[item.value] }}{{ item.unit }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-divider class="title-divider" content-position="left">企业营业信息</el-divider>

          <div class="jg-acc">
            <div class="card-box">
              <div v-for="(item, index) in companyInfo" :key="index" class="card-item">
                <div class="item_label">{{ item.name }}:</div>
                <div>
                  <span v-if="item.name == '营业执照有效期'">
                    {{ dataDetails.content.auditEnterpriseEntity.establishmentDate }}至{{
                      dataDetails.content.auditEnterpriseEntity.validDate
                    }}
                  </span>
                  <span v-else>{{ dataDetails.content.auditEnterpriseEntity[item.value] }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetails.type != 3">
            <el-divider class="title-divider" content-position="left">企业法人信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in personInformation" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>
                  <div>
                    {{ dataDetails.content.auditEnterpriseEntity[item.value] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              dataDetails.content.auditEnterpriseEntity.authorizationName &&
                dataDetails.content.auditEnterpriseEntity.authorizationName != null &&
                dataDetails.type != 3
            "
          >
            <el-divider class="title-divider" content-position="left">授权人信息</el-divider>
            <div class="jg-acc">
              <div class="card-box">
                <div v-for="(item, index) in AuthorizedInformation" :key="index" class="card-item">
                  <div class="item_label">{{ item.name }}:</div>
                  <div>
                    {{ dataDetails.content.auditEnterpriseEntity[item.value] }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="dataDetails.content.auditAttachmentEntityList && dataDetails.content.auditAttachmentEntityList.length">
            <imgdialog
              :code="dataDetails.content.auditEnterpriseEntity.id"
              :file-arr="dataDetails.content.auditAttachmentEntityList"
            />
            <filedialog
              :code="dataDetails.content.auditEnterpriseEntity.id"
              :file-arr="dataDetails.content.auditAttachmentEntityList"
            />
          </div>
        </section>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
import CaseRecord from '@/views/operate/components/CaseRecord.vue';
import addCase from '@/views/operate/components/addCase';
import examine from '../components/examine';
import customer from '../components/customer';
import RatePlan from '../components/RatePlan';
import creditReport from '../components/creditReport';
import imgdialog from '@/views/operate/components/imgdialog.vue';
import filedialog from '@/views/operate/components/filedialog.vue';

export default {
  name: 'TableUser',
  components: {
    CaseRecord,
    addCase,
    examine,
    customer,
    RatePlan,
    creditReport,
    imgdialog,
    filedialog
  },
  data() {
    return {
      visable: false,
      activeName: '0',
      tabList: [
        { label: '待审核', value: '0' },
        { label: '已审核', value: '1' }
      ],
      Ratesvisible: false,
      creditvisible: false,
      casedialog: false,
      Caseisvisible: false,
      examinedialog: false,
      Customerdialog: false,
      searchItmes: {},
      option: {
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        searchShow: true,
        header: true,
        menualign: 'left',
        menuWidth: '300',
        editBtn: false,
        delBtn: false,
        align: 'left',
        addBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '店铺名称',
            prop: 'storeName',
            width: 180,
            type: 'input',
            hide: true,
            editDisplay: true,
            addDisplay: false,
            search: true,
            searchSpan: 4,
            searchLabelWidth: 90,
            span: 24
          },
          {
            label: '企业名称',
            prop: 'enterpriseName',
            align: 'left',
            width: 300,
            hide: true,
            search: true,
            searchSpan: 4
          },
          {
            label: '提交时间',
            prop: 'created',
            type: 'date',
            hide: true,
            searchRange: true,
            valueFormat: 'yyyy-MM-dd',
            search: true,
            searchSpan: 4,
            span: 12
          },
          {
            label: '产品类型',
            prop: 'productType',
            type: 'select',
            hide: true,
            dicData: [
              { label: ' 1A-极速收款', value: '0' },
              { label: '2A-极速付款', value: '1' }
            ],
            search: true,
            searchSpan: 4,
            span: 12
          },
          {
            label: '企业名称',
            prop: 'enterpriseName'
          },
          {
            label: '申请店铺',
            prop: 'storeName'
          },
          {
            label: '产品号',
            prop: 'productType'
          },
          {
            label: '审核订单号',
            prop: 'auditNo'
          },
          {
            label: '审核类型',
            prop: 'type'
          },
          {
            label: '状态',
            prop: 'status'
          },
          {
            label: '提交时间',
            prop: 'created'
          },
          {
            label: '审核角色',
            prop: 'currNode'
          },
          {
            label: '操作时间',
            prop: 'updated'
          }
        ]
      },
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 50, 100],
        isAsc: false // 是否倒序
      },
      list: [],
      loading: false,
      listLoading: true,
      form: {},
      rowItem: {},
      loaded: false,
      dataDetails: {
        amount: null,
        auditData: null,
        auditNo: '',
        content: {
          auditAttachmentEntityList: [],
          auditEnterpriseEntity: {},
          auditStoreEntity: {},
        },
        enterpriseId: null,
        enterpriseName: '',
        esignFileList: null,
        id: null,
        mobile: '',
        photoList: null,
        productType: undefined,
        status: undefined,
        storeInfoDTO: undefined,
        type: undefined,
      },
      // 额度信息
      AuditCreditAmountEntity: [
        {
          name: '总授信额度',
          value: 'creditAmount'
        },
        {
          name: '剩余授信额度',
          value: 'residueAmount'
        }
      ],
      Withdraw_info: [
        {
          name: '申请金额',
          value: 'applyAmount',
          unit: '元'
        },
        {
          name: '账户可用资金余额',
          value: 'availableAmount',
          unit: '元'
        },
        {
          name: '账户冻结金额',
          value: 'reservedAmount',
          unit: '元'
        }
      ],
      // 订单信息
      order_info: [
        {
          name: '支付单号',
          value: 'orderNo'
        },
        {
          name: '订单总金额',
          value: 'totalAmount'
        },
        {
          name: '待支付总金额',
          value: 'totalToBePaid'
        },
        {
          name: '待支付定金金额',
          value: 'depositToBePaid'
        },
        {
          name: '待支付全款金额',
          value: 'normalToBePaid'
        },
        {
          name: '综合赊销率',
          value: 'creditRatio'
        },
        {
          name: '订单支付比例',
          value: 'orderPaidRatio'
        }
      ],
      // 企业营业信息
      companyInfo: [
        {
          name: '企业名称',
          value: 'name'
        },
        {
          name: '社会信用代码',
          value: 'creditCode'
        },
        {
          name: '营业执照有效期',
          value: 'validDate'
        },
        {
          name: '企业地址信息',
          value: 'address'
        }

      ],
      // 企业法人信息
      personInformation: [
        {
          name: '法人姓名',
          value: 'legalName'
        },
        {
          name: '法人身份证',
          value: 'legalCard'
        },
        {
          name: '身份证有效期',
          value: 'legalDuration'
        },
        {
          name: '法人联系邮箱',
          value: 'legalMail'
        },
        {
          name: '法人手机号码',
          value: 'legalMobile'
        },
        {
          name: '民族',
          value: 'legalEthnic'
        }
      ],
      // 授权人信息
      AuthorizedInformation: [
        {
          name: '授权人姓名',
          value: 'authorizationName'
        },
        {
          name: '授权人身份证',
          value: 'authorizationCard'
        },
        {
          name: '身份证有效期',
          value: 'authorizationDuration'
        },
        {
          name: '授权人邮箱',
          value: 'authorizationMail'
        },
        {
          name: '授权人联系电话',
          value: 'authorizationMobile'
        },
        {
          name: '民族',
          value: 'authorizationEthnic'
        }
      ],
      // 客户信息
      customerInfo: [
        {
          name: '客户名称',
          value: 'enterpriseName'
        },
        {
          name: '订单号',
          value: 'auditNo'
        },
        {
          name: '联系方式',
          value: 'mobile'
        },
        {
          name: '审核类型',
          value: 'type'
        },
        {
          name: '状态',
          value: 'status'
        }
      ],
      // 费率信息
      direct: [
        {
          name: '资金方',
          value: 'funderName'
        },
        {
          name: '企业归属',
          value: 'channelName'
        },
        {
          name: '对客费率方案',
          value: 'btn'
        }
      ],
      // 费率信息
      directsType: [
        {
          name: '资金方',
          value: 'funderName'
        },
        {
          name: '每日还款比例',
          value: 'dailyRepaymentRatio'
        },
        {
          name: '对客费率（年化）',
          value: 'customerRate'
        },
        {
          name: '用款周期',
          value: 'paymentDays'
        },
        {
          name: '渠道归属',
          value: 'channelName'
        }
      ],
      // 店铺信息
      shopformation: [
        {
          name: '店铺名称',
          value: 'name'
        },
        {
          name: '店铺所属平台',
          value: 'livePlatform'
        },
        {
          name: '店铺ID',
          value: 'storeCode'
        }
      ],
      // 支用信息
      Disbursementformation: [
        {
          name: '支用金额',
          value: 'applyAmount',
          unit: '元'
        },
        {
          name: '待结算金额',
          value: 'unsettledOrderAmount',
          unit: '元'
        },
        {
          name: '风控折扣率',
          value: 'riskDiscountRate',
          unit: '%'
        },
        {
          name: '实际折扣率',
          value: 'realityDiscountRate',
          unit: '%'
        },
        {
          name: '对客费率',
          value: 'customerRate',
          unit: '% /单笔'
        },
        {
          name: '订单回溯天数',
          value: 'orderTraceDays',
          unit: '天'
        },
        {
          name: '可支用商品订单笔数',
          value: 'orderCount',
          unit: '笔'
        }
      ],
      virtualAcctStatusDesc: '',
      storeStatusDesc: ''
    };
  },
  created() {
  },
  methods: {
    handleClick(item, index) {
      console.log(item, index);

      this.activeName = item.index;
      this.getList(this.page, this.searchItmes);
    },
    /** 关闭弹窗 */
    handleClose() {
      this.Caseisvisible = false;
      this.Ratesvisible = false;
      this.creditvisible = false;
      this.casedialog = false;
      this.examinedialog = false;
      this.Customerdialog = false;
      this.getList(this.page, this.searchItmes);
    },
    openDIalog(title, row) {
      console.log(title);

      if (title === 'examinedialog' && row.type == 2 && row.currNode == 'financial') {
        this.doPost('bium-portal/controller/fin-ops/audit/getApplyAuditAmount', {
          id: row.id
        }).then(({ data }) => {
          this.rowItem = row;
          this[title] = true;
          this.rowItem.amount = data.result;
        });
      } else if (title === 'Ratesvisible') {
        this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlan', {
          storeId: row.storeId
        }).then(({ data }) => {
          if (data.result == null) {
            this.$message.error('请先绑定客户');
          } else {
            this[title] = true;
            this.rowItem = row;
          }
        });
      } else {
        this[title] = true;
        this.rowItem = row;
      }
    },

    getList(page, params) {
      console.log(this.activeName);

      params = params || {
        ...this.searchItmes
      };
      this.listLoading = true;
      if (params.created) {
        params.startTime = params.created[0] + ' 00:00:00';
        params.endTime = params.created[1] + ' 23:59:59';
      }
      params.isAudit = this.activeName == '0';
      params.type = 1;
      params.pageNum = page.currentPage;
      params.pageSize = page.pageSize;
      this.doPost('bium-portal/controller/fin-ops/audit/listAuditPage', params).then(({ data }) => {
        this.list = data.result.records || [];
        this.page.total = data.result.total || 0;
      });
      this.listLoading = false;
    },
    handleApproval(row, index) {
      this.$router.push({ path: '/wfTaskInfo/Taskdetails?id=' + row.id });
      // this.getData(row.id);

    },
    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param || {};
      this.getList(this.page, param);
      done();
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {
      } else if (type === 'add') {
      }
      show();
    },
    getshopData(id) {
      this.doPost('/bium-portal/controller/fin-ops/store/approval/get', {
        storeId: id
      }).then(({ data }) => {
        this.storeStatusDesc = data.result.storeStatusDesc;
        this.virtualAcctStatusDesc = data.result.virtualAcctStatusDesc;
      });
    },
    toConfigStandardPlan(dataItem) {
      const comp = () => import('../../expense/components-plan/plan-view.vue');
      this.$modalDialog(comp, { dataItem });
    },
    getData(id) {
      this.loaded = true;
      this.doPost('/bium-portal/controller/fin-ops/audit/getAuditDetail', {
        id: id
      }).then(({ data }) => {
        this.dataDetails = data.result;
        if (data.result.content.auditApplyEntity && data.result.content.auditApplyEntity.isZCX == true) {
          this.Disbursementformation.push({
            name: '建行授信金额',
            value: 'ccbCreditAmount',
            unit: ''
          },
          {
            name: '建行实际用信申请金额',
            value: 'ccbApplyCreditAmount',
            unit: ''
          });
        }
        console.log('this.Disbursementformation[4]', this.dataDetails);
        if (data.result.content.auditApplyEntity && data.result.content.auditApplyEntity.billingMethod == 'Annualized') {
          this.Disbursementformation[4].unit = '% /年化';
        } else {
          this.Disbursementformation[4].unit = '% /单笔';
        }
        this.Loading = false;
        if (data.result.content.auditStoreEntity && data.result.content.auditStoreEntity.id) {
          this.getshopData(data.result.content.auditStoreEntity.id);
        }
        this.visable = true;
      });
      this.loaded = false;
    },
    goPath(path, query) {
      this.$router.push({
        path: path,
        query: query
      });
    },
    openPath(path, query) {
      const { href } = this.$router.resolve({
        path: path,
        query: query
      });
      window.open(href, '_blank');
    }
  }
};
</script>

<style scoped>
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
</style>
